@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);@import url(https://fonts.googleapis.com/css?family=Anton|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
body {
  background-color: #fff !important;
}
.md-mobile .revenda-1 .btn {
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
#app .container {
    width: 1220px;
    max-width: 100%;
}
}
#app h2 {
  color: #fa8443;
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 1000;
  color: #fa8443;
  margin: 0;
}
#app hc {
  color: #fa8443;
  font-family: 'Roboto', sans-serif;
  font-size: 23px;
  font-weight: 1000;
  color: #fa8443;
  margin: 0;
}
#app h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #fa8443;
}
#app h4 {
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  font-weight: 100;
}
#app p {
  color: #fff;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}
.btn {
  background-color: #fa8443;
  letter-spacing: 1px;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 1000;
}
.btn:hover {
  background-color: #f48d6a;
}
.title {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 50px;
}
.title .title-top {
    width: 120px;
    background-color: #ccc;
    height: 1px;
    margin: 0 auto;
    padding: 0;
}
.title .title-top1 {
    width: 100px;
    background-color: #ccc;
    height: 1px;
    padding: 0;
}
.title span {
    font-weight: 300;
}
.title .title-top-star {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.title .title-top-star i {
      color: #fff;
      font-size: 40px;
      margin: 0 5px;
}
.zone {
  margin: 61px 0;
}
.box {
  background-color: #1b1a1a;
  padding: 30px 0 0 0;
  position: relative;
  width: 100%;
}
.box .box-title {
    padding: 10px;
    color: #fff;
    text-align: center;
    width: 100%;
    width: 100%;
}
.box .box-title h3 {
      color: #fff !important;
      margin: 0;
      text-transform: uppercase;
}
.box .box-content {
    text-align: center;
    display: block;
    padding: 0 10px;
}
.box .box-content p {
      color: #ccc !important;
}
.box .box-content h2 {
      text-transform: uppercase;
      margin: 20px 0 !important;
      font-size: 18px !important;
}
.box .box-content h4 {
      font-size: 18px !important;
      color: #ccc !important;
      text-align: center;
      margin: 0;
      margin-bottom: 8px !important;
      font-weight: 300 !important;
}
.box .btn {
    margin-top: 30px;
    border-radius: 0;
    position: relative;
}
.zone1 {
  background-color: #1b1a1a;
  padding: 61px 0;
}
.zone1 h2 {
    margin-bottom: 10px !important;
}
.zone1 i {
    color: #fa8443 !important;
    margin-right: 4px !important;
}
.zone2 {
  padding: 61px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.zone2 .box4 {
    background-color: #1b1a1a;
    padding: 20px 15px;
    position: relative;
}
.zone2 .box4 h2 {
      margin-bottom: 10px !important;
      font-size: 22px !important;
}
.zone2 .box4 .btn {
      margin-top: 5px;
      border-radius: 0;
      position: relative;
}
.zone5 {
  background-color: #1b1a1a;
  background-image: url(/static/image/revenda.png);
  background-size: cover;
  padding: 61px 0;
  width: 100%;
  height: 100%;
}
footer {
  background-color: #1b1a1a;
  padding: 30px 0;
  text-align: center;
}
footer .footer-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #fff;
}
footer p {
    font-size: 19px;
    font-weight: 300;
}
footer .btn {
    margin-right: 10px;
    border-radius: 25px;
    background-color: transparent;
    border: solid 1px #fff;
}
footer .btn1:hover {
    border: 0;
}
body {
  background-color: #0b0a0a !important;
}
@media (min-width: 1200px) {
.container {
    width: 1220px;
    max-width: 100%;
}
}

.navbar-module {
  min-height: 80px;
  background-color: #1b1a1a;
}
.navbar-module .navbar-brand {
    width: 150px;
    background-repeat: no-repeat;
    background-position: center;
    color: transparent !important;
}
.navbar-module button.navbar-toggler {
    outline: none;
}
.navbar-module .collapsing, .navbar-module .collapse.show {
    padding: 30px 0px;
}
.navbar-module .collapsing ul.navbar-nav li.panel-client, .navbar-module .collapse.show ul.navbar-nav li.panel-client {
      margin-top: 15px;
      margin-left: 0;
      background-color: #fa8443;
}
.navbar-module .collapsing ul.navbar-nav li.dropdown .dropdown-menu, .navbar-module .collapse.show ul.navbar-nav li.dropdown .dropdown-menu {
      display: none;
}
.navbar-module .collapsing ul.navbar-nav li.dropdown.show .dropdown-menu, .navbar-module .collapse.show ul.navbar-nav li.dropdown.show .dropdown-menu {
      display: block;
}
.navbar-module ul.navbar-nav li a.nav-link {
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
}
.navbar-module ul.navbar-nav li a.nav-link:hover {
      color: #fff;
}
.navbar-module ul.navbar-nav li.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}
.navbar-module ul.navbar-nav li .dropdown-toggle::after {
    content: '\F140' !important;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 0;
    line-height: 0;
    position: relative;
    font-size: 22px;
    top: 8px;
    margin: 0 18px 0 5px;
}
.navbar-module ul.navbar-nav li .dropdown-menu {
    border: 0;
    padding: 15px;
    margin-top: 0;
}
.navbar-module ul.navbar-nav li .dropdown-menu a.dropdown-item {
      padding: 0.60rem 1.5rem;
      font-size: 18px;
      outline: none;
      border-radius: 4px;
}
.navbar-module ul.navbar-nav li .dropdown-menu a.dropdown-item i {
        margin-right: 10px;
}
.navbar-module ul.navbar-nav li.panel-client {
    background-color: #fa8443;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 25px;
    margin-left: 20px;
    line-height: 21px;
    text-align: center;
}
.navbar-module ul.navbar-nav li.panel-client:hover {
      border-color: #fff;
}
.navbar-module ul.navbar-nav li.panel-client a {
      padding: 5px 15px;
}
.navbar-module.notcolored {
    min-height: 80px;
    margin-bottom: -80px;
    background-color: transparent;
}
.navbar-module.notcolored ul.navbar-nav li a.nav-link {
      color: #fff;
}
.navbar-module.notcolored ul.navbar-nav li a.nav-link:hover {
        color: fff;
}
.navbar-module.notcolored ul.navbar-nav li.panel-client {
      border-color: #fff;
}
.navbar-module.notcolored ul.navbar-nav li.panel-client:hover {
        border-color: fff;
}
@media (max-width: 767.98px) {
.navbar-module.notcolored {
    position: absolute;
    left: 0;
    right: 0;
}
.navbar-module.notcolored .navbar-brand {
      width: 135px !important;
}
.navbar-module.notcolored .collapsing, .navbar-module.notcolored .collapse.show {
      background-color: #fff;
      padding: 15px;
      margin-top: 20px;
      border-radius: 4px;
}
.navbar-module.notcolored .collapsing ul li a.nav-link, .navbar-module.notcolored .collapse.show ul li a.nav-link {
        text-transform: uppercase;
        font-weight: 600;
        color: #737373;
}
.navbar-module.notcolored .collapsing ul li a.nav-link:hover, .navbar-module.notcolored .collapse.show ul li a.nav-link:hover {
          color: #737373;
}
.navbar-module.notcolored .collapsing ul li.panel-client, .navbar-module.notcolored .collapse.show ul li.panel-client {
        background-color: #fa8443;
        border-color: #737373 !important;
}
.navbar-module.notcolored .collapsing .dropdown-menu, .navbar-module.notcolored .collapse.show .dropdown-menu {
        padding: 10px;
        border: 1px solid #e6e6e6;
}
}
@media only screen and (min-width: 1024px) and (max-width: 1124px) {
.navbar-module .navbar-brand {
    width: 115px !important;
}
.navbar-module ul li a.nav-link {
    font-size: 14px;
    font-weight: 800;
}
.navbar-module ul li.panel-client {
    background-color: #fa8443;
}
.navbar-module ul li.panel-client a {
      padding: 0 10px;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
.navbar-module .navbar-brand {
    width: 95px !important;
}
.navbar-module ul li a.nav-link {
    font-size: 10px;
}
.navbar-module ul li .dropdown-toggle::after {
    font-size: 17px !important;
    top: 7px !important;
    margin: 0 15px 0 3px !important;
}
.navbar-module ul li.panel-client {
    background-color: #fa8443;
}
.navbar-module ul li.panel-client a {
      padding: 0 10px !important;
      line-height: 27px;
}
}
.nav-title {
  color: #FFF;
  font-size: 28PX;
  font-weight: 500;
}
.nav-title span {
    color: #fa8443;
}
/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url(/static/fonts/materialdesignicons-webfont.c28acdf.eot);
  src: url(/static/fonts/materialdesignicons-webfont.c28acdf.eot?#iefix&v=2.2.43) format("embedded-opentype"), url(/static/fonts/materialdesignicons-webfont.32562ea.woff2) format("woff2"), url(/static/fonts/materialdesignicons-webfont.40f483d.woff) format("woff"), url(/static/fonts/materialdesignicons-webfont.a33808e.ttf) format("truetype"), url(/static/img/materialdesignicons-webfont.07c3c54.svg#materialdesigniconsregular) format("svg");
  font-weight: normal;
  font-style: normal; }
.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
.mdi-access-point:before {
  content: "\F002"; }
.mdi-access-point-network:before {
  content: "\F003"; }
.mdi-account:before {
  content: "\F004"; }
.mdi-account-alert:before {
  content: "\F005"; }
.mdi-account-box:before {
  content: "\F006"; }
.mdi-account-box-outline:before {
  content: "\F007"; }
.mdi-account-card-details:before {
  content: "\F5D2"; }
.mdi-account-check:before {
  content: "\F008"; }
.mdi-account-circle:before {
  content: "\F009"; }
.mdi-account-convert:before {
  content: "\F00A"; }
.mdi-account-edit:before {
  content: "\F6BB"; }
.mdi-account-group:before {
  content: "\F848"; }
.mdi-account-heart:before {
  content: "\F898"; }
.mdi-account-key:before {
  content: "\F00B"; }
.mdi-account-location:before {
  content: "\F00C"; }
.mdi-account-minus:before {
  content: "\F00D"; }
.mdi-account-multiple:before {
  content: "\F00E"; }
.mdi-account-multiple-minus:before {
  content: "\F5D3"; }
.mdi-account-multiple-outline:before {
  content: "\F00F"; }
.mdi-account-multiple-plus:before {
  content: "\F010"; }
.mdi-account-multiple-plus-outline:before {
  content: "\F7FF"; }
.mdi-account-network:before {
  content: "\F011"; }
.mdi-account-off:before {
  content: "\F012"; }
.mdi-account-outline:before {
  content: "\F013"; }
.mdi-account-plus:before {
  content: "\F014"; }
.mdi-account-plus-outline:before {
  content: "\F800"; }
.mdi-account-remove:before {
  content: "\F015"; }
.mdi-account-search:before {
  content: "\F016"; }
.mdi-account-settings:before {
  content: "\F630"; }
.mdi-account-settings-variant:before {
  content: "\F631"; }
.mdi-account-star:before {
  content: "\F017"; }
.mdi-account-switch:before {
  content: "\F019"; }
.mdi-accusoft:before {
  content: "\F849"; }
.mdi-adjust:before {
  content: "\F01A"; }
.mdi-air-conditioner:before {
  content: "\F01B"; }
.mdi-airballoon:before {
  content: "\F01C"; }
.mdi-airplane:before {
  content: "\F01D"; }
.mdi-airplane-landing:before {
  content: "\F5D4"; }
.mdi-airplane-off:before {
  content: "\F01E"; }
.mdi-airplane-takeoff:before {
  content: "\F5D5"; }
.mdi-airplay:before {
  content: "\F01F"; }
.mdi-airport:before {
  content: "\F84A"; }
.mdi-alarm:before {
  content: "\F020"; }
.mdi-alarm-bell:before {
  content: "\F78D"; }
.mdi-alarm-check:before {
  content: "\F021"; }
.mdi-alarm-light:before {
  content: "\F78E"; }
.mdi-alarm-multiple:before {
  content: "\F022"; }
.mdi-alarm-off:before {
  content: "\F023"; }
.mdi-alarm-plus:before {
  content: "\F024"; }
.mdi-alarm-snooze:before {
  content: "\F68D"; }
.mdi-album:before {
  content: "\F025"; }
.mdi-alert:before {
  content: "\F026"; }
.mdi-alert-box:before {
  content: "\F027"; }
.mdi-alert-circle:before {
  content: "\F028"; }
.mdi-alert-circle-outline:before {
  content: "\F5D6"; }
.mdi-alert-decagram:before {
  content: "\F6BC"; }
.mdi-alert-octagon:before {
  content: "\F029"; }
.mdi-alert-octagram:before {
  content: "\F766"; }
.mdi-alert-outline:before {
  content: "\F02A"; }
.mdi-alien:before {
  content: "\F899"; }
.mdi-all-inclusive:before {
  content: "\F6BD"; }
.mdi-allo:before {
  content: "\F801"; }
.mdi-alpha:before {
  content: "\F02B"; }
.mdi-alphabetical:before {
  content: "\F02C"; }
.mdi-altimeter:before {
  content: "\F5D7"; }
.mdi-amazon:before {
  content: "\F02D"; }
.mdi-amazon-clouddrive:before {
  content: "\F02E"; }
.mdi-ambulance:before {
  content: "\F02F"; }
.mdi-amplifier:before {
  content: "\F030"; }
.mdi-anchor:before {
  content: "\F031"; }
.mdi-android:before {
  content: "\F032"; }
.mdi-android-debug-bridge:before {
  content: "\F033"; }
.mdi-android-head:before {
  content: "\F78F"; }
.mdi-android-studio:before {
  content: "\F034"; }
.mdi-angular:before {
  content: "\F6B1"; }
.mdi-angularjs:before {
  content: "\F6BE"; }
.mdi-animation:before {
  content: "\F5D8"; }
.mdi-anvil:before {
  content: "\F89A"; }
.mdi-apple:before {
  content: "\F035"; }
.mdi-apple-finder:before {
  content: "\F036"; }
.mdi-apple-ios:before {
  content: "\F037"; }
.mdi-apple-keyboard-caps:before {
  content: "\F632"; }
.mdi-apple-keyboard-command:before {
  content: "\F633"; }
.mdi-apple-keyboard-control:before {
  content: "\F634"; }
.mdi-apple-keyboard-option:before {
  content: "\F635"; }
.mdi-apple-keyboard-shift:before {
  content: "\F636"; }
.mdi-apple-mobileme:before {
  content: "\F038"; }
.mdi-apple-safari:before {
  content: "\F039"; }
.mdi-application:before {
  content: "\F614"; }
.mdi-approval:before {
  content: "\F790"; }
.mdi-apps:before {
  content: "\F03B"; }
.mdi-archive:before {
  content: "\F03C"; }
.mdi-arrange-bring-forward:before {
  content: "\F03D"; }
.mdi-arrange-bring-to-front:before {
  content: "\F03E"; }
.mdi-arrange-send-backward:before {
  content: "\F03F"; }
.mdi-arrange-send-to-back:before {
  content: "\F040"; }
.mdi-arrow-all:before {
  content: "\F041"; }
.mdi-arrow-bottom-left:before {
  content: "\F042"; }
.mdi-arrow-bottom-right:before {
  content: "\F043"; }
.mdi-arrow-collapse:before {
  content: "\F615"; }
.mdi-arrow-collapse-all:before {
  content: "\F044"; }
.mdi-arrow-collapse-down:before {
  content: "\F791"; }
.mdi-arrow-collapse-horizontal:before {
  content: "\F84B"; }
.mdi-arrow-collapse-left:before {
  content: "\F792"; }
.mdi-arrow-collapse-right:before {
  content: "\F793"; }
.mdi-arrow-collapse-up:before {
  content: "\F794"; }
.mdi-arrow-collapse-vertical:before {
  content: "\F84C"; }
.mdi-arrow-down:before {
  content: "\F045"; }
.mdi-arrow-down-bold:before {
  content: "\F72D"; }
.mdi-arrow-down-bold-box:before {
  content: "\F72E"; }
.mdi-arrow-down-bold-box-outline:before {
  content: "\F72F"; }
.mdi-arrow-down-bold-circle:before {
  content: "\F047"; }
.mdi-arrow-down-bold-circle-outline:before {
  content: "\F048"; }
.mdi-arrow-down-bold-hexagon-outline:before {
  content: "\F049"; }
.mdi-arrow-down-box:before {
  content: "\F6BF"; }
.mdi-arrow-down-drop-circle:before {
  content: "\F04A"; }
.mdi-arrow-down-drop-circle-outline:before {
  content: "\F04B"; }
.mdi-arrow-down-thick:before {
  content: "\F046"; }
.mdi-arrow-expand:before {
  content: "\F616"; }
.mdi-arrow-expand-all:before {
  content: "\F04C"; }
.mdi-arrow-expand-down:before {
  content: "\F795"; }
.mdi-arrow-expand-horizontal:before {
  content: "\F84D"; }
.mdi-arrow-expand-left:before {
  content: "\F796"; }
.mdi-arrow-expand-right:before {
  content: "\F797"; }
.mdi-arrow-expand-up:before {
  content: "\F798"; }
.mdi-arrow-expand-vertical:before {
  content: "\F84E"; }
.mdi-arrow-left:before {
  content: "\F04D"; }
.mdi-arrow-left-bold:before {
  content: "\F730"; }
.mdi-arrow-left-bold-box:before {
  content: "\F731"; }
.mdi-arrow-left-bold-box-outline:before {
  content: "\F732"; }
.mdi-arrow-left-bold-circle:before {
  content: "\F04F"; }
.mdi-arrow-left-bold-circle-outline:before {
  content: "\F050"; }
.mdi-arrow-left-bold-hexagon-outline:before {
  content: "\F051"; }
.mdi-arrow-left-box:before {
  content: "\F6C0"; }
.mdi-arrow-left-drop-circle:before {
  content: "\F052"; }
.mdi-arrow-left-drop-circle-outline:before {
  content: "\F053"; }
.mdi-arrow-left-thick:before {
  content: "\F04E"; }
.mdi-arrow-right:before {
  content: "\F054"; }
.mdi-arrow-right-bold:before {
  content: "\F733"; }
.mdi-arrow-right-bold-box:before {
  content: "\F734"; }
.mdi-arrow-right-bold-box-outline:before {
  content: "\F735"; }
.mdi-arrow-right-bold-circle:before {
  content: "\F056"; }
.mdi-arrow-right-bold-circle-outline:before {
  content: "\F057"; }
.mdi-arrow-right-bold-hexagon-outline:before {
  content: "\F058"; }
.mdi-arrow-right-box:before {
  content: "\F6C1"; }
.mdi-arrow-right-drop-circle:before {
  content: "\F059"; }
.mdi-arrow-right-drop-circle-outline:before {
  content: "\F05A"; }
.mdi-arrow-right-thick:before {
  content: "\F055"; }
.mdi-arrow-top-left:before {
  content: "\F05B"; }
.mdi-arrow-top-right:before {
  content: "\F05C"; }
.mdi-arrow-up:before {
  content: "\F05D"; }
.mdi-arrow-up-bold:before {
  content: "\F736"; }
.mdi-arrow-up-bold-box:before {
  content: "\F737"; }
.mdi-arrow-up-bold-box-outline:before {
  content: "\F738"; }
.mdi-arrow-up-bold-circle:before {
  content: "\F05F"; }
.mdi-arrow-up-bold-circle-outline:before {
  content: "\F060"; }
.mdi-arrow-up-bold-hexagon-outline:before {
  content: "\F061"; }
.mdi-arrow-up-box:before {
  content: "\F6C2"; }
.mdi-arrow-up-drop-circle:before {
  content: "\F062"; }
.mdi-arrow-up-drop-circle-outline:before {
  content: "\F063"; }
.mdi-arrow-up-thick:before {
  content: "\F05E"; }
.mdi-artist:before {
  content: "\F802"; }
.mdi-assistant:before {
  content: "\F064"; }
.mdi-asterisk:before {
  content: "\F6C3"; }
.mdi-at:before {
  content: "\F065"; }
.mdi-atlassian:before {
  content: "\F803"; }
.mdi-atom:before {
  content: "\F767"; }
.mdi-attachment:before {
  content: "\F066"; }
.mdi-audiobook:before {
  content: "\F067"; }
.mdi-augmented-reality:before {
  content: "\F84F"; }
.mdi-auto-fix:before {
  content: "\F068"; }
.mdi-auto-upload:before {
  content: "\F069"; }
.mdi-autorenew:before {
  content: "\F06A"; }
.mdi-av-timer:before {
  content: "\F06B"; }
.mdi-azure:before {
  content: "\F804"; }
.mdi-baby:before {
  content: "\F06C"; }
.mdi-baby-buggy:before {
  content: "\F68E"; }
.mdi-backburger:before {
  content: "\F06D"; }
.mdi-backspace:before {
  content: "\F06E"; }
.mdi-backup-restore:before {
  content: "\F06F"; }
.mdi-badminton:before {
  content: "\F850"; }
.mdi-bandcamp:before {
  content: "\F674"; }
.mdi-bank:before {
  content: "\F070"; }
.mdi-barcode:before {
  content: "\F071"; }
.mdi-barcode-scan:before {
  content: "\F072"; }
.mdi-barley:before {
  content: "\F073"; }
.mdi-barrel:before {
  content: "\F074"; }
.mdi-baseball:before {
  content: "\F851"; }
.mdi-baseball-bat:before {
  content: "\F852"; }
.mdi-basecamp:before {
  content: "\F075"; }
.mdi-basket:before {
  content: "\F076"; }
.mdi-basket-fill:before {
  content: "\F077"; }
.mdi-basket-unfill:before {
  content: "\F078"; }
.mdi-basketball:before {
  content: "\F805"; }
.mdi-battery:before {
  content: "\F079"; }
.mdi-battery-10:before {
  content: "\F07A"; }
.mdi-battery-20:before {
  content: "\F07B"; }
.mdi-battery-30:before {
  content: "\F07C"; }
.mdi-battery-40:before {
  content: "\F07D"; }
.mdi-battery-50:before {
  content: "\F07E"; }
.mdi-battery-60:before {
  content: "\F07F"; }
.mdi-battery-70:before {
  content: "\F080"; }
.mdi-battery-80:before {
  content: "\F081"; }
.mdi-battery-90:before {
  content: "\F082"; }
.mdi-battery-alert:before {
  content: "\F083"; }
.mdi-battery-charging:before {
  content: "\F084"; }
.mdi-battery-charging-10:before {
  content: "\F89B"; }
.mdi-battery-charging-100:before {
  content: "\F085"; }
.mdi-battery-charging-20:before {
  content: "\F086"; }
.mdi-battery-charging-30:before {
  content: "\F087"; }
.mdi-battery-charging-40:before {
  content: "\F088"; }
.mdi-battery-charging-50:before {
  content: "\F89C"; }
.mdi-battery-charging-60:before {
  content: "\F089"; }
.mdi-battery-charging-70:before {
  content: "\F89D"; }
.mdi-battery-charging-80:before {
  content: "\F08A"; }
.mdi-battery-charging-90:before {
  content: "\F08B"; }
.mdi-battery-charging-outline:before {
  content: "\F89E"; }
.mdi-battery-charging-wireless:before {
  content: "\F806"; }
.mdi-battery-charging-wireless-10:before {
  content: "\F807"; }
.mdi-battery-charging-wireless-20:before {
  content: "\F808"; }
.mdi-battery-charging-wireless-30:before {
  content: "\F809"; }
.mdi-battery-charging-wireless-40:before {
  content: "\F80A"; }
.mdi-battery-charging-wireless-50:before {
  content: "\F80B"; }
.mdi-battery-charging-wireless-60:before {
  content: "\F80C"; }
.mdi-battery-charging-wireless-70:before {
  content: "\F80D"; }
.mdi-battery-charging-wireless-80:before {
  content: "\F80E"; }
.mdi-battery-charging-wireless-90:before {
  content: "\F80F"; }
.mdi-battery-charging-wireless-alert:before {
  content: "\F810"; }
.mdi-battery-charging-wireless-outline:before {
  content: "\F811"; }
.mdi-battery-minus:before {
  content: "\F08C"; }
.mdi-battery-negative:before {
  content: "\F08D"; }
.mdi-battery-outline:before {
  content: "\F08E"; }
.mdi-battery-plus:before {
  content: "\F08F"; }
.mdi-battery-positive:before {
  content: "\F090"; }
.mdi-battery-unknown:before {
  content: "\F091"; }
.mdi-beach:before {
  content: "\F092"; }
.mdi-beaker:before {
  content: "\F68F"; }
.mdi-beats:before {
  content: "\F097"; }
.mdi-bed-empty:before {
  content: "\F89F"; }
.mdi-beer:before {
  content: "\F098"; }
.mdi-behance:before {
  content: "\F099"; }
.mdi-bell:before {
  content: "\F09A"; }
.mdi-bell-off:before {
  content: "\F09B"; }
.mdi-bell-outline:before {
  content: "\F09C"; }
.mdi-bell-plus:before {
  content: "\F09D"; }
.mdi-bell-ring:before {
  content: "\F09E"; }
.mdi-bell-ring-outline:before {
  content: "\F09F"; }
.mdi-bell-sleep:before {
  content: "\F0A0"; }
.mdi-beta:before {
  content: "\F0A1"; }
.mdi-bible:before {
  content: "\F0A2"; }
.mdi-bike:before {
  content: "\F0A3"; }
.mdi-bing:before {
  content: "\F0A4"; }
.mdi-binoculars:before {
  content: "\F0A5"; }
.mdi-bio:before {
  content: "\F0A6"; }
.mdi-biohazard:before {
  content: "\F0A7"; }
.mdi-bitbucket:before {
  content: "\F0A8"; }
.mdi-bitcoin:before {
  content: "\F812"; }
.mdi-black-mesa:before {
  content: "\F0A9"; }
.mdi-blackberry:before {
  content: "\F0AA"; }
.mdi-blender:before {
  content: "\F0AB"; }
.mdi-blinds:before {
  content: "\F0AC"; }
.mdi-block-helper:before {
  content: "\F0AD"; }
.mdi-blogger:before {
  content: "\F0AE"; }
.mdi-bluetooth:before {
  content: "\F0AF"; }
.mdi-bluetooth-audio:before {
  content: "\F0B0"; }
.mdi-bluetooth-connect:before {
  content: "\F0B1"; }
.mdi-bluetooth-off:before {
  content: "\F0B2"; }
.mdi-bluetooth-settings:before {
  content: "\F0B3"; }
.mdi-bluetooth-transfer:before {
  content: "\F0B4"; }
.mdi-blur:before {
  content: "\F0B5"; }
.mdi-blur-linear:before {
  content: "\F0B6"; }
.mdi-blur-off:before {
  content: "\F0B7"; }
.mdi-blur-radial:before {
  content: "\F0B8"; }
.mdi-bomb:before {
  content: "\F690"; }
.mdi-bomb-off:before {
  content: "\F6C4"; }
.mdi-bone:before {
  content: "\F0B9"; }
.mdi-book:before {
  content: "\F0BA"; }
.mdi-book-minus:before {
  content: "\F5D9"; }
.mdi-book-multiple:before {
  content: "\F0BB"; }
.mdi-book-multiple-variant:before {
  content: "\F0BC"; }
.mdi-book-open:before {
  content: "\F0BD"; }
.mdi-book-open-page-variant:before {
  content: "\F5DA"; }
.mdi-book-open-variant:before {
  content: "\F0BE"; }
.mdi-book-plus:before {
  content: "\F5DB"; }
.mdi-book-secure:before {
  content: "\F799"; }
.mdi-book-unsecure:before {
  content: "\F79A"; }
.mdi-book-variant:before {
  content: "\F0BF"; }
.mdi-bookmark:before {
  content: "\F0C0"; }
.mdi-bookmark-check:before {
  content: "\F0C1"; }
.mdi-bookmark-music:before {
  content: "\F0C2"; }
.mdi-bookmark-outline:before {
  content: "\F0C3"; }
.mdi-bookmark-plus:before {
  content: "\F0C5"; }
.mdi-bookmark-plus-outline:before {
  content: "\F0C4"; }
.mdi-bookmark-remove:before {
  content: "\F0C6"; }
.mdi-boombox:before {
  content: "\F5DC"; }
.mdi-bootstrap:before {
  content: "\F6C5"; }
.mdi-border-all:before {
  content: "\F0C7"; }
.mdi-border-all-variant:before {
  content: "\F8A0"; }
.mdi-border-bottom:before {
  content: "\F0C8"; }
.mdi-border-bottom-variant:before {
  content: "\F8A1"; }
.mdi-border-color:before {
  content: "\F0C9"; }
.mdi-border-horizontal:before {
  content: "\F0CA"; }
.mdi-border-inside:before {
  content: "\F0CB"; }
.mdi-border-left:before {
  content: "\F0CC"; }
.mdi-border-left-variant:before {
  content: "\F8A2"; }
.mdi-border-none:before {
  content: "\F0CD"; }
.mdi-border-none-variant:before {
  content: "\F8A3"; }
.mdi-border-outside:before {
  content: "\F0CE"; }
.mdi-border-right:before {
  content: "\F0CF"; }
.mdi-border-right-variant:before {
  content: "\F8A4"; }
.mdi-border-style:before {
  content: "\F0D0"; }
.mdi-border-top:before {
  content: "\F0D1"; }
.mdi-border-top-variant:before {
  content: "\F8A5"; }
.mdi-border-vertical:before {
  content: "\F0D2"; }
.mdi-bottle-wine:before {
  content: "\F853"; }
.mdi-bow-tie:before {
  content: "\F677"; }
.mdi-bowl:before {
  content: "\F617"; }
.mdi-bowling:before {
  content: "\F0D3"; }
.mdi-box:before {
  content: "\F0D4"; }
.mdi-box-cutter:before {
  content: "\F0D5"; }
.mdi-box-shadow:before {
  content: "\F637"; }
.mdi-bridge:before {
  content: "\F618"; }
.mdi-briefcase:before {
  content: "\F0D6"; }
.mdi-briefcase-check:before {
  content: "\F0D7"; }
.mdi-briefcase-download:before {
  content: "\F0D8"; }
.mdi-briefcase-outline:before {
  content: "\F813"; }
.mdi-briefcase-upload:before {
  content: "\F0D9"; }
.mdi-brightness-1:before {
  content: "\F0DA"; }
.mdi-brightness-2:before {
  content: "\F0DB"; }
.mdi-brightness-3:before {
  content: "\F0DC"; }
.mdi-brightness-4:before {
  content: "\F0DD"; }
.mdi-brightness-5:before {
  content: "\F0DE"; }
.mdi-brightness-6:before {
  content: "\F0DF"; }
.mdi-brightness-7:before {
  content: "\F0E0"; }
.mdi-brightness-auto:before {
  content: "\F0E1"; }
.mdi-broom:before {
  content: "\F0E2"; }
.mdi-brush:before {
  content: "\F0E3"; }
.mdi-buffer:before {
  content: "\F619"; }
.mdi-bug:before {
  content: "\F0E4"; }
.mdi-bulletin-board:before {
  content: "\F0E5"; }
.mdi-bullhorn:before {
  content: "\F0E6"; }
.mdi-bullseye:before {
  content: "\F5DD"; }
.mdi-bus:before {
  content: "\F0E7"; }
.mdi-bus-articulated-end:before {
  content: "\F79B"; }
.mdi-bus-articulated-front:before {
  content: "\F79C"; }
.mdi-bus-double-decker:before {
  content: "\F79D"; }
.mdi-bus-school:before {
  content: "\F79E"; }
.mdi-bus-side:before {
  content: "\F79F"; }
.mdi-cached:before {
  content: "\F0E8"; }
.mdi-cake:before {
  content: "\F0E9"; }
.mdi-cake-layered:before {
  content: "\F0EA"; }
.mdi-cake-variant:before {
  content: "\F0EB"; }
.mdi-calculator:before {
  content: "\F0EC"; }
.mdi-calendar:before {
  content: "\F0ED"; }
.mdi-calendar-blank:before {
  content: "\F0EE"; }
.mdi-calendar-check:before {
  content: "\F0EF"; }
.mdi-calendar-clock:before {
  content: "\F0F0"; }
.mdi-calendar-edit:before {
  content: "\F8A6"; }
.mdi-calendar-multiple:before {
  content: "\F0F1"; }
.mdi-calendar-multiple-check:before {
  content: "\F0F2"; }
.mdi-calendar-plus:before {
  content: "\F0F3"; }
.mdi-calendar-question:before {
  content: "\F691"; }
.mdi-calendar-range:before {
  content: "\F678"; }
.mdi-calendar-remove:before {
  content: "\F0F4"; }
.mdi-calendar-text:before {
  content: "\F0F5"; }
.mdi-calendar-today:before {
  content: "\F0F6"; }
.mdi-call-made:before {
  content: "\F0F7"; }
.mdi-call-merge:before {
  content: "\F0F8"; }
.mdi-call-missed:before {
  content: "\F0F9"; }
.mdi-call-received:before {
  content: "\F0FA"; }
.mdi-call-split:before {
  content: "\F0FB"; }
.mdi-camcorder:before {
  content: "\F0FC"; }
.mdi-camcorder-box:before {
  content: "\F0FD"; }
.mdi-camcorder-box-off:before {
  content: "\F0FE"; }
.mdi-camcorder-off:before {
  content: "\F0FF"; }
.mdi-camera:before {
  content: "\F100"; }
.mdi-camera-burst:before {
  content: "\F692"; }
.mdi-camera-enhance:before {
  content: "\F101"; }
.mdi-camera-front:before {
  content: "\F102"; }
.mdi-camera-front-variant:before {
  content: "\F103"; }
.mdi-camera-gopro:before {
  content: "\F7A0"; }
.mdi-camera-iris:before {
  content: "\F104"; }
.mdi-camera-metering-center:before {
  content: "\F7A1"; }
.mdi-camera-metering-matrix:before {
  content: "\F7A2"; }
.mdi-camera-metering-partial:before {
  content: "\F7A3"; }
.mdi-camera-metering-spot:before {
  content: "\F7A4"; }
.mdi-camera-off:before {
  content: "\F5DF"; }
.mdi-camera-party-mode:before {
  content: "\F105"; }
.mdi-camera-rear:before {
  content: "\F106"; }
.mdi-camera-rear-variant:before {
  content: "\F107"; }
.mdi-camera-switch:before {
  content: "\F108"; }
.mdi-camera-timer:before {
  content: "\F109"; }
.mdi-cancel:before {
  content: "\F739"; }
.mdi-candle:before {
  content: "\F5E2"; }
.mdi-candycane:before {
  content: "\F10A"; }
.mdi-cannabis:before {
  content: "\F7A5"; }
.mdi-car:before {
  content: "\F10B"; }
.mdi-car-battery:before {
  content: "\F10C"; }
.mdi-car-connected:before {
  content: "\F10D"; }
.mdi-car-convertible:before {
  content: "\F7A6"; }
.mdi-car-estate:before {
  content: "\F7A7"; }
.mdi-car-hatchback:before {
  content: "\F7A8"; }
.mdi-car-pickup:before {
  content: "\F7A9"; }
.mdi-car-side:before {
  content: "\F7AA"; }
.mdi-car-sports:before {
  content: "\F7AB"; }
.mdi-car-wash:before {
  content: "\F10E"; }
.mdi-caravan:before {
  content: "\F7AC"; }
.mdi-cards:before {
  content: "\F638"; }
.mdi-cards-outline:before {
  content: "\F639"; }
.mdi-cards-playing-outline:before {
  content: "\F63A"; }
.mdi-cards-variant:before {
  content: "\F6C6"; }
.mdi-carrot:before {
  content: "\F10F"; }
.mdi-cart:before {
  content: "\F110"; }
.mdi-cart-off:before {
  content: "\F66B"; }
.mdi-cart-outline:before {
  content: "\F111"; }
.mdi-cart-plus:before {
  content: "\F112"; }
.mdi-case-sensitive-alt:before {
  content: "\F113"; }
.mdi-cash:before {
  content: "\F114"; }
.mdi-cash-100:before {
  content: "\F115"; }
.mdi-cash-multiple:before {
  content: "\F116"; }
.mdi-cash-usd:before {
  content: "\F117"; }
.mdi-cast:before {
  content: "\F118"; }
.mdi-cast-connected:before {
  content: "\F119"; }
.mdi-cast-off:before {
  content: "\F789"; }
.mdi-castle:before {
  content: "\F11A"; }
.mdi-cat:before {
  content: "\F11B"; }
.mdi-cctv:before {
  content: "\F7AD"; }
.mdi-ceiling-light:before {
  content: "\F768"; }
.mdi-cellphone:before {
  content: "\F11C"; }
.mdi-cellphone-android:before {
  content: "\F11D"; }
.mdi-cellphone-basic:before {
  content: "\F11E"; }
.mdi-cellphone-dock:before {
  content: "\F11F"; }
.mdi-cellphone-iphone:before {
  content: "\F120"; }
.mdi-cellphone-link:before {
  content: "\F121"; }
.mdi-cellphone-link-off:before {
  content: "\F122"; }
.mdi-cellphone-settings:before {
  content: "\F123"; }
.mdi-cellphone-wireless:before {
  content: "\F814"; }
.mdi-certificate:before {
  content: "\F124"; }
.mdi-chair-school:before {
  content: "\F125"; }
.mdi-chart-arc:before {
  content: "\F126"; }
.mdi-chart-areaspline:before {
  content: "\F127"; }
.mdi-chart-bar:before {
  content: "\F128"; }
.mdi-chart-bar-stacked:before {
  content: "\F769"; }
.mdi-chart-bubble:before {
  content: "\F5E3"; }
.mdi-chart-donut:before {
  content: "\F7AE"; }
.mdi-chart-donut-variant:before {
  content: "\F7AF"; }
.mdi-chart-gantt:before {
  content: "\F66C"; }
.mdi-chart-histogram:before {
  content: "\F129"; }
.mdi-chart-line:before {
  content: "\F12A"; }
.mdi-chart-line-stacked:before {
  content: "\F76A"; }
.mdi-chart-line-variant:before {
  content: "\F7B0"; }
.mdi-chart-pie:before {
  content: "\F12B"; }
.mdi-chart-scatterplot-hexbin:before {
  content: "\F66D"; }
.mdi-chart-timeline:before {
  content: "\F66E"; }
.mdi-check:before {
  content: "\F12C"; }
.mdi-check-all:before {
  content: "\F12D"; }
.mdi-check-circle:before {
  content: "\F5E0"; }
.mdi-check-circle-outline:before {
  content: "\F5E1"; }
.mdi-check-outline:before {
  content: "\F854"; }
.mdi-checkbox-blank:before {
  content: "\F12E"; }
.mdi-checkbox-blank-circle:before {
  content: "\F12F"; }
.mdi-checkbox-blank-circle-outline:before {
  content: "\F130"; }
.mdi-checkbox-blank-outline:before {
  content: "\F131"; }
.mdi-checkbox-intermediate:before {
  content: "\F855"; }
.mdi-checkbox-marked:before {
  content: "\F132"; }
.mdi-checkbox-marked-circle:before {
  content: "\F133"; }
.mdi-checkbox-marked-circle-outline:before {
  content: "\F134"; }
.mdi-checkbox-marked-outline:before {
  content: "\F135"; }
.mdi-checkbox-multiple-blank:before {
  content: "\F136"; }
.mdi-checkbox-multiple-blank-circle:before {
  content: "\F63B"; }
.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "\F63C"; }
.mdi-checkbox-multiple-blank-outline:before {
  content: "\F137"; }
.mdi-checkbox-multiple-marked:before {
  content: "\F138"; }
.mdi-checkbox-multiple-marked-circle:before {
  content: "\F63D"; }
.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "\F63E"; }
.mdi-checkbox-multiple-marked-outline:before {
  content: "\F139"; }
.mdi-checkerboard:before {
  content: "\F13A"; }
.mdi-chemical-weapon:before {
  content: "\F13B"; }
.mdi-chess-bishop:before {
  content: "\F85B"; }
.mdi-chess-king:before {
  content: "\F856"; }
.mdi-chess-knight:before {
  content: "\F857"; }
.mdi-chess-pawn:before {
  content: "\F858"; }
.mdi-chess-queen:before {
  content: "\F859"; }
.mdi-chess-rook:before {
  content: "\F85A"; }
.mdi-chevron-double-down:before {
  content: "\F13C"; }
.mdi-chevron-double-left:before {
  content: "\F13D"; }
.mdi-chevron-double-right:before {
  content: "\F13E"; }
.mdi-chevron-double-up:before {
  content: "\F13F"; }
.mdi-chevron-down:before {
  content: "\F140"; }
.mdi-chevron-left:before {
  content: "\F141"; }
.mdi-chevron-right:before {
  content: "\F142"; }
.mdi-chevron-up:before {
  content: "\F143"; }
.mdi-chili-hot:before {
  content: "\F7B1"; }
.mdi-chili-medium:before {
  content: "\F7B2"; }
.mdi-chili-mild:before {
  content: "\F7B3"; }
.mdi-chip:before {
  content: "\F61A"; }
.mdi-church:before {
  content: "\F144"; }
.mdi-circle:before {
  content: "\F764"; }
.mdi-circle-outline:before {
  content: "\F765"; }
.mdi-cisco-webex:before {
  content: "\F145"; }
.mdi-city:before {
  content: "\F146"; }
.mdi-clipboard:before {
  content: "\F147"; }
.mdi-clipboard-account:before {
  content: "\F148"; }
.mdi-clipboard-alert:before {
  content: "\F149"; }
.mdi-clipboard-arrow-down:before {
  content: "\F14A"; }
.mdi-clipboard-arrow-left:before {
  content: "\F14B"; }
.mdi-clipboard-check:before {
  content: "\F14C"; }
.mdi-clipboard-check-outline:before {
  content: "\F8A7"; }
.mdi-clipboard-flow:before {
  content: "\F6C7"; }
.mdi-clipboard-outline:before {
  content: "\F14D"; }
.mdi-clipboard-plus:before {
  content: "\F750"; }
.mdi-clipboard-pulse:before {
  content: "\F85C"; }
.mdi-clipboard-pulse-outline:before {
  content: "\F85D"; }
.mdi-clipboard-text:before {
  content: "\F14E"; }
.mdi-clippy:before {
  content: "\F14F"; }
.mdi-clock:before {
  content: "\F150"; }
.mdi-clock-alert:before {
  content: "\F5CE"; }
.mdi-clock-end:before {
  content: "\F151"; }
.mdi-clock-fast:before {
  content: "\F152"; }
.mdi-clock-in:before {
  content: "\F153"; }
.mdi-clock-out:before {
  content: "\F154"; }
.mdi-clock-start:before {
  content: "\F155"; }
.mdi-close:before {
  content: "\F156"; }
.mdi-close-box:before {
  content: "\F157"; }
.mdi-close-box-outline:before {
  content: "\F158"; }
.mdi-close-circle:before {
  content: "\F159"; }
.mdi-close-circle-outline:before {
  content: "\F15A"; }
.mdi-close-network:before {
  content: "\F15B"; }
.mdi-close-octagon:before {
  content: "\F15C"; }
.mdi-close-octagon-outline:before {
  content: "\F15D"; }
.mdi-close-outline:before {
  content: "\F6C8"; }
.mdi-closed-caption:before {
  content: "\F15E"; }
.mdi-cloud:before {
  content: "\F15F"; }
.mdi-cloud-braces:before {
  content: "\F7B4"; }
.mdi-cloud-check:before {
  content: "\F160"; }
.mdi-cloud-circle:before {
  content: "\F161"; }
.mdi-cloud-download:before {
  content: "\F162"; }
.mdi-cloud-off-outline:before {
  content: "\F164"; }
.mdi-cloud-outline:before {
  content: "\F163"; }
.mdi-cloud-print:before {
  content: "\F165"; }
.mdi-cloud-print-outline:before {
  content: "\F166"; }
.mdi-cloud-sync:before {
  content: "\F63F"; }
.mdi-cloud-tags:before {
  content: "\F7B5"; }
.mdi-cloud-upload:before {
  content: "\F167"; }
.mdi-clover:before {
  content: "\F815"; }
.mdi-code-array:before {
  content: "\F168"; }
.mdi-code-braces:before {
  content: "\F169"; }
.mdi-code-brackets:before {
  content: "\F16A"; }
.mdi-code-equal:before {
  content: "\F16B"; }
.mdi-code-greater-than:before {
  content: "\F16C"; }
.mdi-code-greater-than-or-equal:before {
  content: "\F16D"; }
.mdi-code-less-than:before {
  content: "\F16E"; }
.mdi-code-less-than-or-equal:before {
  content: "\F16F"; }
.mdi-code-not-equal:before {
  content: "\F170"; }
.mdi-code-not-equal-variant:before {
  content: "\F171"; }
.mdi-code-parentheses:before {
  content: "\F172"; }
.mdi-code-string:before {
  content: "\F173"; }
.mdi-code-tags:before {
  content: "\F174"; }
.mdi-code-tags-check:before {
  content: "\F693"; }
.mdi-codepen:before {
  content: "\F175"; }
.mdi-coffee:before {
  content: "\F176"; }
.mdi-coffee-outline:before {
  content: "\F6C9"; }
.mdi-coffee-to-go:before {
  content: "\F177"; }
.mdi-coin:before {
  content: "\F178"; }
.mdi-coins:before {
  content: "\F694"; }
.mdi-collage:before {
  content: "\F640"; }
.mdi-color-helper:before {
  content: "\F179"; }
.mdi-comment:before {
  content: "\F17A"; }
.mdi-comment-account:before {
  content: "\F17B"; }
.mdi-comment-account-outline:before {
  content: "\F17C"; }
.mdi-comment-alert:before {
  content: "\F17D"; }
.mdi-comment-alert-outline:before {
  content: "\F17E"; }
.mdi-comment-check:before {
  content: "\F17F"; }
.mdi-comment-check-outline:before {
  content: "\F180"; }
.mdi-comment-multiple:before {
  content: "\F85E"; }
.mdi-comment-multiple-outline:before {
  content: "\F181"; }
.mdi-comment-outline:before {
  content: "\F182"; }
.mdi-comment-plus-outline:before {
  content: "\F183"; }
.mdi-comment-processing:before {
  content: "\F184"; }
.mdi-comment-processing-outline:before {
  content: "\F185"; }
.mdi-comment-question:before {
  content: "\F816"; }
.mdi-comment-question-outline:before {
  content: "\F186"; }
.mdi-comment-remove:before {
  content: "\F5DE"; }
.mdi-comment-remove-outline:before {
  content: "\F187"; }
.mdi-comment-text:before {
  content: "\F188"; }
.mdi-comment-text-multiple:before {
  content: "\F85F"; }
.mdi-comment-text-multiple-outline:before {
  content: "\F860"; }
.mdi-comment-text-outline:before {
  content: "\F189"; }
.mdi-compare:before {
  content: "\F18A"; }
.mdi-compass:before {
  content: "\F18B"; }
.mdi-compass-outline:before {
  content: "\F18C"; }
.mdi-console:before {
  content: "\F18D"; }
.mdi-console-line:before {
  content: "\F7B6"; }
.mdi-console-network:before {
  content: "\F8A8"; }
.mdi-contact-mail:before {
  content: "\F18E"; }
.mdi-contacts:before {
  content: "\F6CA"; }
.mdi-content-copy:before {
  content: "\F18F"; }
.mdi-content-cut:before {
  content: "\F190"; }
.mdi-content-duplicate:before {
  content: "\F191"; }
.mdi-content-paste:before {
  content: "\F192"; }
.mdi-content-save:before {
  content: "\F193"; }
.mdi-content-save-all:before {
  content: "\F194"; }
.mdi-content-save-outline:before {
  content: "\F817"; }
.mdi-content-save-settings:before {
  content: "\F61B"; }
.mdi-contrast:before {
  content: "\F195"; }
.mdi-contrast-box:before {
  content: "\F196"; }
.mdi-contrast-circle:before {
  content: "\F197"; }
.mdi-cookie:before {
  content: "\F198"; }
.mdi-copyright:before {
  content: "\F5E6"; }
.mdi-corn:before {
  content: "\F7B7"; }
.mdi-counter:before {
  content: "\F199"; }
.mdi-cow:before {
  content: "\F19A"; }
.mdi-crane:before {
  content: "\F861"; }
.mdi-creation:before {
  content: "\F1C9"; }
.mdi-credit-card:before {
  content: "\F19B"; }
.mdi-credit-card-multiple:before {
  content: "\F19C"; }
.mdi-credit-card-off:before {
  content: "\F5E4"; }
.mdi-credit-card-plus:before {
  content: "\F675"; }
.mdi-credit-card-scan:before {
  content: "\F19D"; }
.mdi-crop:before {
  content: "\F19E"; }
.mdi-crop-free:before {
  content: "\F19F"; }
.mdi-crop-landscape:before {
  content: "\F1A0"; }
.mdi-crop-portrait:before {
  content: "\F1A1"; }
.mdi-crop-rotate:before {
  content: "\F695"; }
.mdi-crop-square:before {
  content: "\F1A2"; }
.mdi-crosshairs:before {
  content: "\F1A3"; }
.mdi-crosshairs-gps:before {
  content: "\F1A4"; }
.mdi-crown:before {
  content: "\F1A5"; }
.mdi-cube:before {
  content: "\F1A6"; }
.mdi-cube-outline:before {
  content: "\F1A7"; }
.mdi-cube-send:before {
  content: "\F1A8"; }
.mdi-cube-unfolded:before {
  content: "\F1A9"; }
.mdi-cup:before {
  content: "\F1AA"; }
.mdi-cup-off:before {
  content: "\F5E5"; }
.mdi-cup-water:before {
  content: "\F1AB"; }
.mdi-curling:before {
  content: "\F862"; }
.mdi-currency-bdt:before {
  content: "\F863"; }
.mdi-currency-btc:before {
  content: "\F1AC"; }
.mdi-currency-chf:before {
  content: "\F7B8"; }
.mdi-currency-cny:before {
  content: "\F7B9"; }
.mdi-currency-eth:before {
  content: "\F7BA"; }
.mdi-currency-eur:before {
  content: "\F1AD"; }
.mdi-currency-gbp:before {
  content: "\F1AE"; }
.mdi-currency-inr:before {
  content: "\F1AF"; }
.mdi-currency-jpy:before {
  content: "\F7BB"; }
.mdi-currency-krw:before {
  content: "\F7BC"; }
.mdi-currency-kzt:before {
  content: "\F864"; }
.mdi-currency-ngn:before {
  content: "\F1B0"; }
.mdi-currency-rub:before {
  content: "\F1B1"; }
.mdi-currency-sign:before {
  content: "\F7BD"; }
.mdi-currency-try:before {
  content: "\F1B2"; }
.mdi-currency-twd:before {
  content: "\F7BE"; }
.mdi-currency-usd:before {
  content: "\F1B3"; }
.mdi-currency-usd-off:before {
  content: "\F679"; }
.mdi-cursor-default:before {
  content: "\F1B4"; }
.mdi-cursor-default-outline:before {
  content: "\F1B5"; }
.mdi-cursor-move:before {
  content: "\F1B6"; }
.mdi-cursor-pointer:before {
  content: "\F1B7"; }
.mdi-cursor-text:before {
  content: "\F5E7"; }
.mdi-database:before {
  content: "\F1B8"; }
.mdi-database-minus:before {
  content: "\F1B9"; }
.mdi-database-plus:before {
  content: "\F1BA"; }
.mdi-database-search:before {
  content: "\F865"; }
.mdi-debug-step-into:before {
  content: "\F1BB"; }
.mdi-debug-step-out:before {
  content: "\F1BC"; }
.mdi-debug-step-over:before {
  content: "\F1BD"; }
.mdi-decagram:before {
  content: "\F76B"; }
.mdi-decagram-outline:before {
  content: "\F76C"; }
.mdi-decimal-decrease:before {
  content: "\F1BE"; }
.mdi-decimal-increase:before {
  content: "\F1BF"; }
.mdi-delete:before {
  content: "\F1C0"; }
.mdi-delete-circle:before {
  content: "\F682"; }
.mdi-delete-empty:before {
  content: "\F6CB"; }
.mdi-delete-forever:before {
  content: "\F5E8"; }
.mdi-delete-restore:before {
  content: "\F818"; }
.mdi-delete-sweep:before {
  content: "\F5E9"; }
.mdi-delete-variant:before {
  content: "\F1C1"; }
.mdi-delta:before {
  content: "\F1C2"; }
.mdi-deskphone:before {
  content: "\F1C3"; }
.mdi-desktop-classic:before {
  content: "\F7BF"; }
.mdi-desktop-mac:before {
  content: "\F1C4"; }
.mdi-desktop-tower:before {
  content: "\F1C5"; }
.mdi-details:before {
  content: "\F1C6"; }
.mdi-developer-board:before {
  content: "\F696"; }
.mdi-deviantart:before {
  content: "\F1C7"; }
.mdi-dialpad:before {
  content: "\F61C"; }
.mdi-diamond:before {
  content: "\F1C8"; }
.mdi-dice-1:before {
  content: "\F1CA"; }
.mdi-dice-2:before {
  content: "\F1CB"; }
.mdi-dice-3:before {
  content: "\F1CC"; }
.mdi-dice-4:before {
  content: "\F1CD"; }
.mdi-dice-5:before {
  content: "\F1CE"; }
.mdi-dice-6:before {
  content: "\F1CF"; }
.mdi-dice-d10:before {
  content: "\F76E"; }
.mdi-dice-d12:before {
  content: "\F866"; }
.mdi-dice-d20:before {
  content: "\F5EA"; }
.mdi-dice-d4:before {
  content: "\F5EB"; }
.mdi-dice-d6:before {
  content: "\F5EC"; }
.mdi-dice-d8:before {
  content: "\F5ED"; }
.mdi-dice-multiple:before {
  content: "\F76D"; }
.mdi-dictionary:before {
  content: "\F61D"; }
.mdi-dip-switch:before {
  content: "\F7C0"; }
.mdi-directions:before {
  content: "\F1D0"; }
.mdi-directions-fork:before {
  content: "\F641"; }
.mdi-discord:before {
  content: "\F66F"; }
.mdi-disk:before {
  content: "\F5EE"; }
.mdi-disk-alert:before {
  content: "\F1D1"; }
.mdi-disqus:before {
  content: "\F1D2"; }
.mdi-disqus-outline:before {
  content: "\F1D3"; }
.mdi-division:before {
  content: "\F1D4"; }
.mdi-division-box:before {
  content: "\F1D5"; }
.mdi-dna:before {
  content: "\F683"; }
.mdi-dns:before {
  content: "\F1D6"; }
.mdi-do-not-disturb:before {
  content: "\F697"; }
.mdi-do-not-disturb-off:before {
  content: "\F698"; }
.mdi-docker:before {
  content: "\F867"; }
.mdi-dolby:before {
  content: "\F6B2"; }
.mdi-domain:before {
  content: "\F1D7"; }
.mdi-donkey:before {
  content: "\F7C1"; }
.mdi-door:before {
  content: "\F819"; }
.mdi-door-closed:before {
  content: "\F81A"; }
.mdi-door-open:before {
  content: "\F81B"; }
.mdi-doorbell-video:before {
  content: "\F868"; }
.mdi-dots-horizontal:before {
  content: "\F1D8"; }
.mdi-dots-horizontal-circle:before {
  content: "\F7C2"; }
.mdi-dots-vertical:before {
  content: "\F1D9"; }
.mdi-dots-vertical-circle:before {
  content: "\F7C3"; }
.mdi-douban:before {
  content: "\F699"; }
.mdi-download:before {
  content: "\F1DA"; }
.mdi-download-network:before {
  content: "\F6F3"; }
.mdi-drag:before {
  content: "\F1DB"; }
.mdi-drag-horizontal:before {
  content: "\F1DC"; }
.mdi-drag-vertical:before {
  content: "\F1DD"; }
.mdi-drawing:before {
  content: "\F1DE"; }
.mdi-drawing-box:before {
  content: "\F1DF"; }
.mdi-dribbble:before {
  content: "\F1E0"; }
.mdi-dribbble-box:before {
  content: "\F1E1"; }
.mdi-drone:before {
  content: "\F1E2"; }
.mdi-dropbox:before {
  content: "\F1E3"; }
.mdi-drupal:before {
  content: "\F1E4"; }
.mdi-duck:before {
  content: "\F1E5"; }
.mdi-dumbbell:before {
  content: "\F1E6"; }
.mdi-ear-hearing:before {
  content: "\F7C4"; }
.mdi-earth:before {
  content: "\F1E7"; }
.mdi-earth-box:before {
  content: "\F6CC"; }
.mdi-earth-box-off:before {
  content: "\F6CD"; }
.mdi-earth-off:before {
  content: "\F1E8"; }
.mdi-edge:before {
  content: "\F1E9"; }
.mdi-eject:before {
  content: "\F1EA"; }
.mdi-elephant:before {
  content: "\F7C5"; }
.mdi-elevation-decline:before {
  content: "\F1EB"; }
.mdi-elevation-rise:before {
  content: "\F1EC"; }
.mdi-elevator:before {
  content: "\F1ED"; }
.mdi-email:before {
  content: "\F1EE"; }
.mdi-email-alert:before {
  content: "\F6CE"; }
.mdi-email-open:before {
  content: "\F1EF"; }
.mdi-email-open-outline:before {
  content: "\F5EF"; }
.mdi-email-outline:before {
  content: "\F1F0"; }
.mdi-email-secure:before {
  content: "\F1F1"; }
.mdi-email-variant:before {
  content: "\F5F0"; }
.mdi-emby:before {
  content: "\F6B3"; }
.mdi-emoticon:before {
  content: "\F1F2"; }
.mdi-emoticon-cool:before {
  content: "\F1F3"; }
.mdi-emoticon-dead:before {
  content: "\F69A"; }
.mdi-emoticon-devil:before {
  content: "\F1F4"; }
.mdi-emoticon-excited:before {
  content: "\F69B"; }
.mdi-emoticon-happy:before {
  content: "\F1F5"; }
.mdi-emoticon-neutral:before {
  content: "\F1F6"; }
.mdi-emoticon-poop:before {
  content: "\F1F7"; }
.mdi-emoticon-sad:before {
  content: "\F1F8"; }
.mdi-emoticon-tongue:before {
  content: "\F1F9"; }
.mdi-engine:before {
  content: "\F1FA"; }
.mdi-engine-outline:before {
  content: "\F1FB"; }
.mdi-equal:before {
  content: "\F1FC"; }
.mdi-equal-box:before {
  content: "\F1FD"; }
.mdi-eraser:before {
  content: "\F1FE"; }
.mdi-eraser-variant:before {
  content: "\F642"; }
.mdi-escalator:before {
  content: "\F1FF"; }
.mdi-ethereum:before {
  content: "\F869"; }
.mdi-ethernet:before {
  content: "\F200"; }
.mdi-ethernet-cable:before {
  content: "\F201"; }
.mdi-ethernet-cable-off:before {
  content: "\F202"; }
.mdi-etsy:before {
  content: "\F203"; }
.mdi-ev-station:before {
  content: "\F5F1"; }
.mdi-eventbrite:before {
  content: "\F7C6"; }
.mdi-evernote:before {
  content: "\F204"; }
.mdi-exclamation:before {
  content: "\F205"; }
.mdi-exit-to-app:before {
  content: "\F206"; }
.mdi-export:before {
  content: "\F207"; }
.mdi-eye:before {
  content: "\F208"; }
.mdi-eye-off:before {
  content: "\F209"; }
.mdi-eye-off-outline:before {
  content: "\F6D0"; }
.mdi-eye-outline:before {
  content: "\F6CF"; }
.mdi-eye-plus:before {
  content: "\F86A"; }
.mdi-eye-plus-outline:before {
  content: "\F86B"; }
.mdi-eye-settings:before {
  content: "\F86C"; }
.mdi-eye-settings-outline:before {
  content: "\F86D"; }
.mdi-eyedropper:before {
  content: "\F20A"; }
.mdi-eyedropper-variant:before {
  content: "\F20B"; }
.mdi-face:before {
  content: "\F643"; }
.mdi-face-profile:before {
  content: "\F644"; }
.mdi-facebook:before {
  content: "\F20C"; }
.mdi-facebook-box:before {
  content: "\F20D"; }
.mdi-facebook-messenger:before {
  content: "\F20E"; }
.mdi-factory:before {
  content: "\F20F"; }
.mdi-fan:before {
  content: "\F210"; }
.mdi-fan-off:before {
  content: "\F81C"; }
.mdi-fast-forward:before {
  content: "\F211"; }
.mdi-fast-forward-outline:before {
  content: "\F6D1"; }
.mdi-fax:before {
  content: "\F212"; }
.mdi-feather:before {
  content: "\F6D2"; }
.mdi-ferry:before {
  content: "\F213"; }
.mdi-file:before {
  content: "\F214"; }
.mdi-file-account:before {
  content: "\F73A"; }
.mdi-file-chart:before {
  content: "\F215"; }
.mdi-file-check:before {
  content: "\F216"; }
.mdi-file-cloud:before {
  content: "\F217"; }
.mdi-file-compare:before {
  content: "\F8A9"; }
.mdi-file-delimited:before {
  content: "\F218"; }
.mdi-file-document:before {
  content: "\F219"; }
.mdi-file-document-box:before {
  content: "\F21A"; }
.mdi-file-excel:before {
  content: "\F21B"; }
.mdi-file-excel-box:before {
  content: "\F21C"; }
.mdi-file-export:before {
  content: "\F21D"; }
.mdi-file-find:before {
  content: "\F21E"; }
.mdi-file-hidden:before {
  content: "\F613"; }
.mdi-file-image:before {
  content: "\F21F"; }
.mdi-file-import:before {
  content: "\F220"; }
.mdi-file-lock:before {
  content: "\F221"; }
.mdi-file-multiple:before {
  content: "\F222"; }
.mdi-file-music:before {
  content: "\F223"; }
.mdi-file-outline:before {
  content: "\F224"; }
.mdi-file-pdf:before {
  content: "\F225"; }
.mdi-file-pdf-box:before {
  content: "\F226"; }
.mdi-file-percent:before {
  content: "\F81D"; }
.mdi-file-plus:before {
  content: "\F751"; }
.mdi-file-powerpoint:before {
  content: "\F227"; }
.mdi-file-powerpoint-box:before {
  content: "\F228"; }
.mdi-file-presentation-box:before {
  content: "\F229"; }
.mdi-file-question:before {
  content: "\F86E"; }
.mdi-file-restore:before {
  content: "\F670"; }
.mdi-file-send:before {
  content: "\F22A"; }
.mdi-file-tree:before {
  content: "\F645"; }
.mdi-file-video:before {
  content: "\F22B"; }
.mdi-file-word:before {
  content: "\F22C"; }
.mdi-file-word-box:before {
  content: "\F22D"; }
.mdi-file-xml:before {
  content: "\F22E"; }
.mdi-film:before {
  content: "\F22F"; }
.mdi-filmstrip:before {
  content: "\F230"; }
.mdi-filmstrip-off:before {
  content: "\F231"; }
.mdi-filter:before {
  content: "\F232"; }
.mdi-filter-outline:before {
  content: "\F233"; }
.mdi-filter-remove:before {
  content: "\F234"; }
.mdi-filter-remove-outline:before {
  content: "\F235"; }
.mdi-filter-variant:before {
  content: "\F236"; }
.mdi-finance:before {
  content: "\F81E"; }
.mdi-find-replace:before {
  content: "\F6D3"; }
.mdi-fingerprint:before {
  content: "\F237"; }
.mdi-fire:before {
  content: "\F238"; }
.mdi-fire-truck:before {
  content: "\F8AA"; }
.mdi-firefox:before {
  content: "\F239"; }
.mdi-fish:before {
  content: "\F23A"; }
.mdi-flag:before {
  content: "\F23B"; }
.mdi-flag-checkered:before {
  content: "\F23C"; }
.mdi-flag-outline:before {
  content: "\F23D"; }
.mdi-flag-triangle:before {
  content: "\F23F"; }
.mdi-flag-variant:before {
  content: "\F240"; }
.mdi-flag-variant-outline:before {
  content: "\F23E"; }
.mdi-flash:before {
  content: "\F241"; }
.mdi-flash-auto:before {
  content: "\F242"; }
.mdi-flash-circle:before {
  content: "\F81F"; }
.mdi-flash-off:before {
  content: "\F243"; }
.mdi-flash-outline:before {
  content: "\F6D4"; }
.mdi-flash-red-eye:before {
  content: "\F67A"; }
.mdi-flashlight:before {
  content: "\F244"; }
.mdi-flashlight-off:before {
  content: "\F245"; }
.mdi-flask:before {
  content: "\F093"; }
.mdi-flask-empty:before {
  content: "\F094"; }
.mdi-flask-empty-outline:before {
  content: "\F095"; }
.mdi-flask-outline:before {
  content: "\F096"; }
.mdi-flattr:before {
  content: "\F246"; }
.mdi-flip-to-back:before {
  content: "\F247"; }
.mdi-flip-to-front:before {
  content: "\F248"; }
.mdi-floor-plan:before {
  content: "\F820"; }
.mdi-floppy:before {
  content: "\F249"; }
.mdi-flower:before {
  content: "\F24A"; }
.mdi-folder:before {
  content: "\F24B"; }
.mdi-folder-account:before {
  content: "\F24C"; }
.mdi-folder-download:before {
  content: "\F24D"; }
.mdi-folder-google-drive:before {
  content: "\F24E"; }
.mdi-folder-image:before {
  content: "\F24F"; }
.mdi-folder-key:before {
  content: "\F8AB"; }
.mdi-folder-key-network:before {
  content: "\F8AC"; }
.mdi-folder-lock:before {
  content: "\F250"; }
.mdi-folder-lock-open:before {
  content: "\F251"; }
.mdi-folder-move:before {
  content: "\F252"; }
.mdi-folder-multiple:before {
  content: "\F253"; }
.mdi-folder-multiple-image:before {
  content: "\F254"; }
.mdi-folder-multiple-outline:before {
  content: "\F255"; }
.mdi-folder-network:before {
  content: "\F86F"; }
.mdi-folder-open:before {
  content: "\F76F"; }
.mdi-folder-outline:before {
  content: "\F256"; }
.mdi-folder-plus:before {
  content: "\F257"; }
.mdi-folder-remove:before {
  content: "\F258"; }
.mdi-folder-star:before {
  content: "\F69C"; }
.mdi-folder-upload:before {
  content: "\F259"; }
.mdi-font-awesome:before {
  content: "\F03A"; }
.mdi-food:before {
  content: "\F25A"; }
.mdi-food-apple:before {
  content: "\F25B"; }
.mdi-food-croissant:before {
  content: "\F7C7"; }
.mdi-food-fork-drink:before {
  content: "\F5F2"; }
.mdi-food-off:before {
  content: "\F5F3"; }
.mdi-food-variant:before {
  content: "\F25C"; }
.mdi-football:before {
  content: "\F25D"; }
.mdi-football-australian:before {
  content: "\F25E"; }
.mdi-football-helmet:before {
  content: "\F25F"; }
.mdi-forklift:before {
  content: "\F7C8"; }
.mdi-format-align-bottom:before {
  content: "\F752"; }
.mdi-format-align-center:before {
  content: "\F260"; }
.mdi-format-align-justify:before {
  content: "\F261"; }
.mdi-format-align-left:before {
  content: "\F262"; }
.mdi-format-align-middle:before {
  content: "\F753"; }
.mdi-format-align-right:before {
  content: "\F263"; }
.mdi-format-align-top:before {
  content: "\F754"; }
.mdi-format-annotation-plus:before {
  content: "\F646"; }
.mdi-format-bold:before {
  content: "\F264"; }
.mdi-format-clear:before {
  content: "\F265"; }
.mdi-format-color-fill:before {
  content: "\F266"; }
.mdi-format-color-text:before {
  content: "\F69D"; }
.mdi-format-float-center:before {
  content: "\F267"; }
.mdi-format-float-left:before {
  content: "\F268"; }
.mdi-format-float-none:before {
  content: "\F269"; }
.mdi-format-float-right:before {
  content: "\F26A"; }
.mdi-format-font:before {
  content: "\F6D5"; }
.mdi-format-header-1:before {
  content: "\F26B"; }
.mdi-format-header-2:before {
  content: "\F26C"; }
.mdi-format-header-3:before {
  content: "\F26D"; }
.mdi-format-header-4:before {
  content: "\F26E"; }
.mdi-format-header-5:before {
  content: "\F26F"; }
.mdi-format-header-6:before {
  content: "\F270"; }
.mdi-format-header-decrease:before {
  content: "\F271"; }
.mdi-format-header-equal:before {
  content: "\F272"; }
.mdi-format-header-increase:before {
  content: "\F273"; }
.mdi-format-header-pound:before {
  content: "\F274"; }
.mdi-format-horizontal-align-center:before {
  content: "\F61E"; }
.mdi-format-horizontal-align-left:before {
  content: "\F61F"; }
.mdi-format-horizontal-align-right:before {
  content: "\F620"; }
.mdi-format-indent-decrease:before {
  content: "\F275"; }
.mdi-format-indent-increase:before {
  content: "\F276"; }
.mdi-format-italic:before {
  content: "\F277"; }
.mdi-format-line-spacing:before {
  content: "\F278"; }
.mdi-format-line-style:before {
  content: "\F5C8"; }
.mdi-format-line-weight:before {
  content: "\F5C9"; }
.mdi-format-list-bulleted:before {
  content: "\F279"; }
.mdi-format-list-bulleted-type:before {
  content: "\F27A"; }
.mdi-format-list-checks:before {
  content: "\F755"; }
.mdi-format-list-numbers:before {
  content: "\F27B"; }
.mdi-format-page-break:before {
  content: "\F6D6"; }
.mdi-format-paint:before {
  content: "\F27C"; }
.mdi-format-paragraph:before {
  content: "\F27D"; }
.mdi-format-pilcrow:before {
  content: "\F6D7"; }
.mdi-format-quote-close:before {
  content: "\F27E"; }
.mdi-format-quote-open:before {
  content: "\F756"; }
.mdi-format-rotate-90:before {
  content: "\F6A9"; }
.mdi-format-section:before {
  content: "\F69E"; }
.mdi-format-size:before {
  content: "\F27F"; }
.mdi-format-strikethrough:before {
  content: "\F280"; }
.mdi-format-strikethrough-variant:before {
  content: "\F281"; }
.mdi-format-subscript:before {
  content: "\F282"; }
.mdi-format-superscript:before {
  content: "\F283"; }
.mdi-format-text:before {
  content: "\F284"; }
.mdi-format-textdirection-l-to-r:before {
  content: "\F285"; }
.mdi-format-textdirection-r-to-l:before {
  content: "\F286"; }
.mdi-format-title:before {
  content: "\F5F4"; }
.mdi-format-underline:before {
  content: "\F287"; }
.mdi-format-vertical-align-bottom:before {
  content: "\F621"; }
.mdi-format-vertical-align-center:before {
  content: "\F622"; }
.mdi-format-vertical-align-top:before {
  content: "\F623"; }
.mdi-format-wrap-inline:before {
  content: "\F288"; }
.mdi-format-wrap-square:before {
  content: "\F289"; }
.mdi-format-wrap-tight:before {
  content: "\F28A"; }
.mdi-format-wrap-top-bottom:before {
  content: "\F28B"; }
.mdi-forum:before {
  content: "\F28C"; }
.mdi-forum-outline:before {
  content: "\F821"; }
.mdi-forward:before {
  content: "\F28D"; }
.mdi-foursquare:before {
  content: "\F28E"; }
.mdi-fridge:before {
  content: "\F28F"; }
.mdi-fridge-filled:before {
  content: "\F290"; }
.mdi-fridge-filled-bottom:before {
  content: "\F291"; }
.mdi-fridge-filled-top:before {
  content: "\F292"; }
.mdi-fuel:before {
  content: "\F7C9"; }
.mdi-fullscreen:before {
  content: "\F293"; }
.mdi-fullscreen-exit:before {
  content: "\F294"; }
.mdi-function:before {
  content: "\F295"; }
.mdi-function-variant:before {
  content: "\F870"; }
.mdi-gamepad:before {
  content: "\F296"; }
.mdi-gamepad-variant:before {
  content: "\F297"; }
.mdi-garage:before {
  content: "\F6D8"; }
.mdi-garage-alert:before {
  content: "\F871"; }
.mdi-garage-open:before {
  content: "\F6D9"; }
.mdi-gas-cylinder:before {
  content: "\F647"; }
.mdi-gas-station:before {
  content: "\F298"; }
.mdi-gate:before {
  content: "\F299"; }
.mdi-gauge:before {
  content: "\F29A"; }
.mdi-gauge-empty:before {
  content: "\F872"; }
.mdi-gauge-full:before {
  content: "\F873"; }
.mdi-gauge-low:before {
  content: "\F874"; }
.mdi-gavel:before {
  content: "\F29B"; }
.mdi-gender-female:before {
  content: "\F29C"; }
.mdi-gender-male:before {
  content: "\F29D"; }
.mdi-gender-male-female:before {
  content: "\F29E"; }
.mdi-gender-transgender:before {
  content: "\F29F"; }
.mdi-gesture:before {
  content: "\F7CA"; }
.mdi-gesture-double-tap:before {
  content: "\F73B"; }
.mdi-gesture-swipe-down:before {
  content: "\F73C"; }
.mdi-gesture-swipe-left:before {
  content: "\F73D"; }
.mdi-gesture-swipe-right:before {
  content: "\F73E"; }
.mdi-gesture-swipe-up:before {
  content: "\F73F"; }
.mdi-gesture-tap:before {
  content: "\F740"; }
.mdi-gesture-two-double-tap:before {
  content: "\F741"; }
.mdi-gesture-two-tap:before {
  content: "\F742"; }
.mdi-ghost:before {
  content: "\F2A0"; }
.mdi-gift:before {
  content: "\F2A1"; }
.mdi-git:before {
  content: "\F2A2"; }
.mdi-github-box:before {
  content: "\F2A3"; }
.mdi-github-circle:before {
  content: "\F2A4"; }
.mdi-github-face:before {
  content: "\F6DA"; }
.mdi-glass-cocktail:before {
  content: "\F356"; }
.mdi-glass-flute:before {
  content: "\F2A5"; }
.mdi-glass-mug:before {
  content: "\F2A6"; }
.mdi-glass-stange:before {
  content: "\F2A7"; }
.mdi-glass-tulip:before {
  content: "\F2A8"; }
.mdi-glass-wine:before {
  content: "\F875"; }
.mdi-glassdoor:before {
  content: "\F2A9"; }
.mdi-glasses:before {
  content: "\F2AA"; }
.mdi-gmail:before {
  content: "\F2AB"; }
.mdi-gnome:before {
  content: "\F2AC"; }
.mdi-golf:before {
  content: "\F822"; }
.mdi-gondola:before {
  content: "\F685"; }
.mdi-google:before {
  content: "\F2AD"; }
.mdi-google-analytics:before {
  content: "\F7CB"; }
.mdi-google-assistant:before {
  content: "\F7CC"; }
.mdi-google-cardboard:before {
  content: "\F2AE"; }
.mdi-google-chrome:before {
  content: "\F2AF"; }
.mdi-google-circles:before {
  content: "\F2B0"; }
.mdi-google-circles-communities:before {
  content: "\F2B1"; }
.mdi-google-circles-extended:before {
  content: "\F2B2"; }
.mdi-google-circles-group:before {
  content: "\F2B3"; }
.mdi-google-controller:before {
  content: "\F2B4"; }
.mdi-google-controller-off:before {
  content: "\F2B5"; }
.mdi-google-drive:before {
  content: "\F2B6"; }
.mdi-google-earth:before {
  content: "\F2B7"; }
.mdi-google-glass:before {
  content: "\F2B8"; }
.mdi-google-home:before {
  content: "\F823"; }
.mdi-google-keep:before {
  content: "\F6DB"; }
.mdi-google-maps:before {
  content: "\F5F5"; }
.mdi-google-nearby:before {
  content: "\F2B9"; }
.mdi-google-pages:before {
  content: "\F2BA"; }
.mdi-google-photos:before {
  content: "\F6DC"; }
.mdi-google-physical-web:before {
  content: "\F2BB"; }
.mdi-google-play:before {
  content: "\F2BC"; }
.mdi-google-plus:before {
  content: "\F2BD"; }
.mdi-google-plus-box:before {
  content: "\F2BE"; }
.mdi-google-translate:before {
  content: "\F2BF"; }
.mdi-google-wallet:before {
  content: "\F2C0"; }
.mdi-gpu:before {
  content: "\F8AD"; }
.mdi-gradient:before {
  content: "\F69F"; }
.mdi-graphql:before {
  content: "\F876"; }
.mdi-grease-pencil:before {
  content: "\F648"; }
.mdi-grid:before {
  content: "\F2C1"; }
.mdi-grid-large:before {
  content: "\F757"; }
.mdi-grid-off:before {
  content: "\F2C2"; }
.mdi-group:before {
  content: "\F2C3"; }
.mdi-guitar-acoustic:before {
  content: "\F770"; }
.mdi-guitar-electric:before {
  content: "\F2C4"; }
.mdi-guitar-pick:before {
  content: "\F2C5"; }
.mdi-guitar-pick-outline:before {
  content: "\F2C6"; }
.mdi-guy-fawkes-mask:before {
  content: "\F824"; }
.mdi-hackernews:before {
  content: "\F624"; }
.mdi-hamburger:before {
  content: "\F684"; }
.mdi-hand-pointing-right:before {
  content: "\F2C7"; }
.mdi-hanger:before {
  content: "\F2C8"; }
.mdi-hangouts:before {
  content: "\F2C9"; }
.mdi-harddisk:before {
  content: "\F2CA"; }
.mdi-headphones:before {
  content: "\F2CB"; }
.mdi-headphones-box:before {
  content: "\F2CC"; }
.mdi-headphones-off:before {
  content: "\F7CD"; }
.mdi-headphones-settings:before {
  content: "\F2CD"; }
.mdi-headset:before {
  content: "\F2CE"; }
.mdi-headset-dock:before {
  content: "\F2CF"; }
.mdi-headset-off:before {
  content: "\F2D0"; }
.mdi-heart:before {
  content: "\F2D1"; }
.mdi-heart-box:before {
  content: "\F2D2"; }
.mdi-heart-box-outline:before {
  content: "\F2D3"; }
.mdi-heart-broken:before {
  content: "\F2D4"; }
.mdi-heart-half:before {
  content: "\F6DE"; }
.mdi-heart-half-full:before {
  content: "\F6DD"; }
.mdi-heart-half-outline:before {
  content: "\F6DF"; }
.mdi-heart-off:before {
  content: "\F758"; }
.mdi-heart-outline:before {
  content: "\F2D5"; }
.mdi-heart-pulse:before {
  content: "\F5F6"; }
.mdi-help:before {
  content: "\F2D6"; }
.mdi-help-box:before {
  content: "\F78A"; }
.mdi-help-circle:before {
  content: "\F2D7"; }
.mdi-help-circle-outline:before {
  content: "\F625"; }
.mdi-help-network:before {
  content: "\F6F4"; }
.mdi-hexagon:before {
  content: "\F2D8"; }
.mdi-hexagon-multiple:before {
  content: "\F6E0"; }
.mdi-hexagon-outline:before {
  content: "\F2D9"; }
.mdi-high-definition:before {
  content: "\F7CE"; }
.mdi-high-definition-box:before {
  content: "\F877"; }
.mdi-highway:before {
  content: "\F5F7"; }
.mdi-history:before {
  content: "\F2DA"; }
.mdi-hockey-puck:before {
  content: "\F878"; }
.mdi-hockey-sticks:before {
  content: "\F879"; }
.mdi-hololens:before {
  content: "\F2DB"; }
.mdi-home:before {
  content: "\F2DC"; }
.mdi-home-account:before {
  content: "\F825"; }
.mdi-home-alert:before {
  content: "\F87A"; }
.mdi-home-assistant:before {
  content: "\F7CF"; }
.mdi-home-automation:before {
  content: "\F7D0"; }
.mdi-home-circle:before {
  content: "\F7D1"; }
.mdi-home-currency-usd:before {
  content: "\F8AE"; }
.mdi-home-heart:before {
  content: "\F826"; }
.mdi-home-map-marker:before {
  content: "\F5F8"; }
.mdi-home-modern:before {
  content: "\F2DD"; }
.mdi-home-outline:before {
  content: "\F6A0"; }
.mdi-home-variant:before {
  content: "\F2DE"; }
.mdi-hook:before {
  content: "\F6E1"; }
.mdi-hook-off:before {
  content: "\F6E2"; }
.mdi-hops:before {
  content: "\F2DF"; }
.mdi-hospital:before {
  content: "\F2E0"; }
.mdi-hospital-building:before {
  content: "\F2E1"; }
.mdi-hospital-marker:before {
  content: "\F2E2"; }
.mdi-hot-tub:before {
  content: "\F827"; }
.mdi-hotel:before {
  content: "\F2E3"; }
.mdi-houzz:before {
  content: "\F2E4"; }
.mdi-houzz-box:before {
  content: "\F2E5"; }
.mdi-hulu:before {
  content: "\F828"; }
.mdi-human:before {
  content: "\F2E6"; }
.mdi-human-child:before {
  content: "\F2E7"; }
.mdi-human-female:before {
  content: "\F649"; }
.mdi-human-greeting:before {
  content: "\F64A"; }
.mdi-human-handsdown:before {
  content: "\F64B"; }
.mdi-human-handsup:before {
  content: "\F64C"; }
.mdi-human-male:before {
  content: "\F64D"; }
.mdi-human-male-female:before {
  content: "\F2E8"; }
.mdi-human-pregnant:before {
  content: "\F5CF"; }
.mdi-humble-bundle:before {
  content: "\F743"; }
.mdi-ice-cream:before {
  content: "\F829"; }
.mdi-image:before {
  content: "\F2E9"; }
.mdi-image-album:before {
  content: "\F2EA"; }
.mdi-image-area:before {
  content: "\F2EB"; }
.mdi-image-area-close:before {
  content: "\F2EC"; }
.mdi-image-broken:before {
  content: "\F2ED"; }
.mdi-image-broken-variant:before {
  content: "\F2EE"; }
.mdi-image-filter:before {
  content: "\F2EF"; }
.mdi-image-filter-black-white:before {
  content: "\F2F0"; }
.mdi-image-filter-center-focus:before {
  content: "\F2F1"; }
.mdi-image-filter-center-focus-weak:before {
  content: "\F2F2"; }
.mdi-image-filter-drama:before {
  content: "\F2F3"; }
.mdi-image-filter-frames:before {
  content: "\F2F4"; }
.mdi-image-filter-hdr:before {
  content: "\F2F5"; }
.mdi-image-filter-none:before {
  content: "\F2F6"; }
.mdi-image-filter-tilt-shift:before {
  content: "\F2F7"; }
.mdi-image-filter-vintage:before {
  content: "\F2F8"; }
.mdi-image-multiple:before {
  content: "\F2F9"; }
.mdi-image-off:before {
  content: "\F82A"; }
.mdi-image-plus:before {
  content: "\F87B"; }
.mdi-import:before {
  content: "\F2FA"; }
.mdi-inbox:before {
  content: "\F686"; }
.mdi-inbox-arrow-down:before {
  content: "\F2FB"; }
.mdi-inbox-arrow-up:before {
  content: "\F3D1"; }
.mdi-inbox-multiple:before {
  content: "\F8AF"; }
.mdi-incognito:before {
  content: "\F5F9"; }
.mdi-infinity:before {
  content: "\F6E3"; }
.mdi-information:before {
  content: "\F2FC"; }
.mdi-information-outline:before {
  content: "\F2FD"; }
.mdi-information-variant:before {
  content: "\F64E"; }
.mdi-instagram:before {
  content: "\F2FE"; }
.mdi-instapaper:before {
  content: "\F2FF"; }
.mdi-internet-explorer:before {
  content: "\F300"; }
.mdi-invert-colors:before {
  content: "\F301"; }
.mdi-itunes:before {
  content: "\F676"; }
.mdi-jeepney:before {
  content: "\F302"; }
.mdi-jira:before {
  content: "\F303"; }
.mdi-jquery:before {
  content: "\F87C"; }
.mdi-jsfiddle:before {
  content: "\F304"; }
.mdi-json:before {
  content: "\F626"; }
.mdi-karate:before {
  content: "\F82B"; }
.mdi-keg:before {
  content: "\F305"; }
.mdi-kettle:before {
  content: "\F5FA"; }
.mdi-key:before {
  content: "\F306"; }
.mdi-key-change:before {
  content: "\F307"; }
.mdi-key-minus:before {
  content: "\F308"; }
.mdi-key-plus:before {
  content: "\F309"; }
.mdi-key-remove:before {
  content: "\F30A"; }
.mdi-key-variant:before {
  content: "\F30B"; }
.mdi-keyboard:before {
  content: "\F30C"; }
.mdi-keyboard-backspace:before {
  content: "\F30D"; }
.mdi-keyboard-caps:before {
  content: "\F30E"; }
.mdi-keyboard-close:before {
  content: "\F30F"; }
.mdi-keyboard-off:before {
  content: "\F310"; }
.mdi-keyboard-return:before {
  content: "\F311"; }
.mdi-keyboard-tab:before {
  content: "\F312"; }
.mdi-keyboard-variant:before {
  content: "\F313"; }
.mdi-kickstarter:before {
  content: "\F744"; }
.mdi-kodi:before {
  content: "\F314"; }
.mdi-label:before {
  content: "\F315"; }
.mdi-label-outline:before {
  content: "\F316"; }
.mdi-ladybug:before {
  content: "\F82C"; }
.mdi-lambda:before {
  content: "\F627"; }
.mdi-lamp:before {
  content: "\F6B4"; }
.mdi-lan:before {
  content: "\F317"; }
.mdi-lan-connect:before {
  content: "\F318"; }
.mdi-lan-disconnect:before {
  content: "\F319"; }
.mdi-lan-pending:before {
  content: "\F31A"; }
.mdi-language-c:before {
  content: "\F671"; }
.mdi-language-cpp:before {
  content: "\F672"; }
.mdi-language-csharp:before {
  content: "\F31B"; }
.mdi-language-css3:before {
  content: "\F31C"; }
.mdi-language-go:before {
  content: "\F7D2"; }
.mdi-language-html5:before {
  content: "\F31D"; }
.mdi-language-javascript:before {
  content: "\F31E"; }
.mdi-language-lua:before {
  content: "\F8B0"; }
.mdi-language-php:before {
  content: "\F31F"; }
.mdi-language-python:before {
  content: "\F320"; }
.mdi-language-python-text:before {
  content: "\F321"; }
.mdi-language-r:before {
  content: "\F7D3"; }
.mdi-language-swift:before {
  content: "\F6E4"; }
.mdi-language-typescript:before {
  content: "\F6E5"; }
.mdi-laptop:before {
  content: "\F322"; }
.mdi-laptop-chromebook:before {
  content: "\F323"; }
.mdi-laptop-mac:before {
  content: "\F324"; }
.mdi-laptop-off:before {
  content: "\F6E6"; }
.mdi-laptop-windows:before {
  content: "\F325"; }
.mdi-lastfm:before {
  content: "\F326"; }
.mdi-lastpass:before {
  content: "\F446"; }
.mdi-launch:before {
  content: "\F327"; }
.mdi-lava-lamp:before {
  content: "\F7D4"; }
.mdi-layers:before {
  content: "\F328"; }
.mdi-layers-off:before {
  content: "\F329"; }
.mdi-lead-pencil:before {
  content: "\F64F"; }
.mdi-leaf:before {
  content: "\F32A"; }
.mdi-led-off:before {
  content: "\F32B"; }
.mdi-led-on:before {
  content: "\F32C"; }
.mdi-led-outline:before {
  content: "\F32D"; }
.mdi-led-strip:before {
  content: "\F7D5"; }
.mdi-led-variant-off:before {
  content: "\F32E"; }
.mdi-led-variant-on:before {
  content: "\F32F"; }
.mdi-led-variant-outline:before {
  content: "\F330"; }
.mdi-library:before {
  content: "\F331"; }
.mdi-library-books:before {
  content: "\F332"; }
.mdi-library-music:before {
  content: "\F333"; }
.mdi-library-plus:before {
  content: "\F334"; }
.mdi-lifebuoy:before {
  content: "\F87D"; }
.mdi-lightbulb:before {
  content: "\F335"; }
.mdi-lightbulb-on:before {
  content: "\F6E7"; }
.mdi-lightbulb-on-outline:before {
  content: "\F6E8"; }
.mdi-lightbulb-outline:before {
  content: "\F336"; }
.mdi-link:before {
  content: "\F337"; }
.mdi-link-off:before {
  content: "\F338"; }
.mdi-link-variant:before {
  content: "\F339"; }
.mdi-link-variant-off:before {
  content: "\F33A"; }
.mdi-linkedin:before {
  content: "\F33B"; }
.mdi-linkedin-box:before {
  content: "\F33C"; }
.mdi-linux:before {
  content: "\F33D"; }
.mdi-loading:before {
  content: "\F771"; }
.mdi-lock:before {
  content: "\F33E"; }
.mdi-lock-open:before {
  content: "\F33F"; }
.mdi-lock-open-outline:before {
  content: "\F340"; }
.mdi-lock-outline:before {
  content: "\F341"; }
.mdi-lock-pattern:before {
  content: "\F6E9"; }
.mdi-lock-plus:before {
  content: "\F5FB"; }
.mdi-lock-reset:before {
  content: "\F772"; }
.mdi-lock-smart:before {
  content: "\F8B1"; }
.mdi-locker:before {
  content: "\F7D6"; }
.mdi-locker-multiple:before {
  content: "\F7D7"; }
.mdi-login:before {
  content: "\F342"; }
.mdi-login-variant:before {
  content: "\F5FC"; }
.mdi-logout:before {
  content: "\F343"; }
.mdi-logout-variant:before {
  content: "\F5FD"; }
.mdi-looks:before {
  content: "\F344"; }
.mdi-loop:before {
  content: "\F6EA"; }
.mdi-loupe:before {
  content: "\F345"; }
.mdi-lumx:before {
  content: "\F346"; }
.mdi-magnet:before {
  content: "\F347"; }
.mdi-magnet-on:before {
  content: "\F348"; }
.mdi-magnify:before {
  content: "\F349"; }
.mdi-magnify-minus:before {
  content: "\F34A"; }
.mdi-magnify-minus-outline:before {
  content: "\F6EB"; }
.mdi-magnify-plus:before {
  content: "\F34B"; }
.mdi-magnify-plus-outline:before {
  content: "\F6EC"; }
.mdi-mail-ru:before {
  content: "\F34C"; }
.mdi-mailbox:before {
  content: "\F6ED"; }
.mdi-map:before {
  content: "\F34D"; }
.mdi-map-marker:before {
  content: "\F34E"; }
.mdi-map-marker-circle:before {
  content: "\F34F"; }
.mdi-map-marker-minus:before {
  content: "\F650"; }
.mdi-map-marker-multiple:before {
  content: "\F350"; }
.mdi-map-marker-off:before {
  content: "\F351"; }
.mdi-map-marker-outline:before {
  content: "\F7D8"; }
.mdi-map-marker-plus:before {
  content: "\F651"; }
.mdi-map-marker-radius:before {
  content: "\F352"; }
.mdi-margin:before {
  content: "\F353"; }
.mdi-markdown:before {
  content: "\F354"; }
.mdi-marker:before {
  content: "\F652"; }
.mdi-marker-check:before {
  content: "\F355"; }
.mdi-material-ui:before {
  content: "\F357"; }
.mdi-math-compass:before {
  content: "\F358"; }
.mdi-matrix:before {
  content: "\F628"; }
.mdi-maxcdn:before {
  content: "\F359"; }
.mdi-medical-bag:before {
  content: "\F6EE"; }
.mdi-medium:before {
  content: "\F35A"; }
.mdi-memory:before {
  content: "\F35B"; }
.mdi-menu:before {
  content: "\F35C"; }
.mdi-menu-down:before {
  content: "\F35D"; }
.mdi-menu-down-outline:before {
  content: "\F6B5"; }
.mdi-menu-left:before {
  content: "\F35E"; }
.mdi-menu-right:before {
  content: "\F35F"; }
.mdi-menu-up:before {
  content: "\F360"; }
.mdi-menu-up-outline:before {
  content: "\F6B6"; }
.mdi-message:before {
  content: "\F361"; }
.mdi-message-alert:before {
  content: "\F362"; }
.mdi-message-bulleted:before {
  content: "\F6A1"; }
.mdi-message-bulleted-off:before {
  content: "\F6A2"; }
.mdi-message-draw:before {
  content: "\F363"; }
.mdi-message-image:before {
  content: "\F364"; }
.mdi-message-outline:before {
  content: "\F365"; }
.mdi-message-plus:before {
  content: "\F653"; }
.mdi-message-processing:before {
  content: "\F366"; }
.mdi-message-reply:before {
  content: "\F367"; }
.mdi-message-reply-text:before {
  content: "\F368"; }
.mdi-message-settings:before {
  content: "\F6EF"; }
.mdi-message-settings-variant:before {
  content: "\F6F0"; }
.mdi-message-text:before {
  content: "\F369"; }
.mdi-message-text-outline:before {
  content: "\F36A"; }
.mdi-message-video:before {
  content: "\F36B"; }
.mdi-meteor:before {
  content: "\F629"; }
.mdi-metronome:before {
  content: "\F7D9"; }
.mdi-metronome-tick:before {
  content: "\F7DA"; }
.mdi-micro-sd:before {
  content: "\F7DB"; }
.mdi-microphone:before {
  content: "\F36C"; }
.mdi-microphone-minus:before {
  content: "\F8B2"; }
.mdi-microphone-off:before {
  content: "\F36D"; }
.mdi-microphone-outline:before {
  content: "\F36E"; }
.mdi-microphone-plus:before {
  content: "\F8B3"; }
.mdi-microphone-settings:before {
  content: "\F36F"; }
.mdi-microphone-variant:before {
  content: "\F370"; }
.mdi-microphone-variant-off:before {
  content: "\F371"; }
.mdi-microscope:before {
  content: "\F654"; }
.mdi-microsoft:before {
  content: "\F372"; }
.mdi-minecraft:before {
  content: "\F373"; }
.mdi-minus:before {
  content: "\F374"; }
.mdi-minus-box:before {
  content: "\F375"; }
.mdi-minus-box-outline:before {
  content: "\F6F1"; }
.mdi-minus-circle:before {
  content: "\F376"; }
.mdi-minus-circle-outline:before {
  content: "\F377"; }
.mdi-minus-network:before {
  content: "\F378"; }
.mdi-mixcloud:before {
  content: "\F62A"; }
.mdi-mixed-reality:before {
  content: "\F87E"; }
.mdi-mixer:before {
  content: "\F7DC"; }
.mdi-monitor:before {
  content: "\F379"; }
.mdi-monitor-multiple:before {
  content: "\F37A"; }
.mdi-more:before {
  content: "\F37B"; }
.mdi-motorbike:before {
  content: "\F37C"; }
.mdi-mouse:before {
  content: "\F37D"; }
.mdi-mouse-off:before {
  content: "\F37E"; }
.mdi-mouse-variant:before {
  content: "\F37F"; }
.mdi-mouse-variant-off:before {
  content: "\F380"; }
.mdi-move-resize:before {
  content: "\F655"; }
.mdi-move-resize-variant:before {
  content: "\F656"; }
.mdi-movie:before {
  content: "\F381"; }
.mdi-movie-roll:before {
  content: "\F7DD"; }
.mdi-multiplication:before {
  content: "\F382"; }
.mdi-multiplication-box:before {
  content: "\F383"; }
.mdi-mushroom:before {
  content: "\F7DE"; }
.mdi-mushroom-outline:before {
  content: "\F7DF"; }
.mdi-music:before {
  content: "\F759"; }
.mdi-music-box:before {
  content: "\F384"; }
.mdi-music-box-outline:before {
  content: "\F385"; }
.mdi-music-circle:before {
  content: "\F386"; }
.mdi-music-note:before {
  content: "\F387"; }
.mdi-music-note-bluetooth:before {
  content: "\F5FE"; }
.mdi-music-note-bluetooth-off:before {
  content: "\F5FF"; }
.mdi-music-note-eighth:before {
  content: "\F388"; }
.mdi-music-note-half:before {
  content: "\F389"; }
.mdi-music-note-off:before {
  content: "\F38A"; }
.mdi-music-note-quarter:before {
  content: "\F38B"; }
.mdi-music-note-sixteenth:before {
  content: "\F38C"; }
.mdi-music-note-whole:before {
  content: "\F38D"; }
.mdi-music-off:before {
  content: "\F75A"; }
.mdi-nativescript:before {
  content: "\F87F"; }
.mdi-nature:before {
  content: "\F38E"; }
.mdi-nature-people:before {
  content: "\F38F"; }
.mdi-navigation:before {
  content: "\F390"; }
.mdi-near-me:before {
  content: "\F5CD"; }
.mdi-needle:before {
  content: "\F391"; }
.mdi-nest-protect:before {
  content: "\F392"; }
.mdi-nest-thermostat:before {
  content: "\F393"; }
.mdi-netflix:before {
  content: "\F745"; }
.mdi-network:before {
  content: "\F6F2"; }
.mdi-new-box:before {
  content: "\F394"; }
.mdi-newspaper:before {
  content: "\F395"; }
.mdi-nfc:before {
  content: "\F396"; }
.mdi-nfc-tap:before {
  content: "\F397"; }
.mdi-nfc-variant:before {
  content: "\F398"; }
.mdi-ninja:before {
  content: "\F773"; }
.mdi-nintendo-switch:before {
  content: "\F7E0"; }
.mdi-nodejs:before {
  content: "\F399"; }
.mdi-note:before {
  content: "\F39A"; }
.mdi-note-multiple:before {
  content: "\F6B7"; }
.mdi-note-multiple-outline:before {
  content: "\F6B8"; }
.mdi-note-outline:before {
  content: "\F39B"; }
.mdi-note-plus:before {
  content: "\F39C"; }
.mdi-note-plus-outline:before {
  content: "\F39D"; }
.mdi-note-text:before {
  content: "\F39E"; }
.mdi-notebook:before {
  content: "\F82D"; }
.mdi-notification-clear-all:before {
  content: "\F39F"; }
.mdi-npm:before {
  content: "\F6F6"; }
.mdi-nuke:before {
  content: "\F6A3"; }
.mdi-null:before {
  content: "\F7E1"; }
.mdi-numeric:before {
  content: "\F3A0"; }
.mdi-numeric-0-box:before {
  content: "\F3A1"; }
.mdi-numeric-0-box-multiple-outline:before {
  content: "\F3A2"; }
.mdi-numeric-0-box-outline:before {
  content: "\F3A3"; }
.mdi-numeric-1-box:before {
  content: "\F3A4"; }
.mdi-numeric-1-box-multiple-outline:before {
  content: "\F3A5"; }
.mdi-numeric-1-box-outline:before {
  content: "\F3A6"; }
.mdi-numeric-2-box:before {
  content: "\F3A7"; }
.mdi-numeric-2-box-multiple-outline:before {
  content: "\F3A8"; }
.mdi-numeric-2-box-outline:before {
  content: "\F3A9"; }
.mdi-numeric-3-box:before {
  content: "\F3AA"; }
.mdi-numeric-3-box-multiple-outline:before {
  content: "\F3AB"; }
.mdi-numeric-3-box-outline:before {
  content: "\F3AC"; }
.mdi-numeric-4-box:before {
  content: "\F3AD"; }
.mdi-numeric-4-box-multiple-outline:before {
  content: "\F3AE"; }
.mdi-numeric-4-box-outline:before {
  content: "\F3AF"; }
.mdi-numeric-5-box:before {
  content: "\F3B0"; }
.mdi-numeric-5-box-multiple-outline:before {
  content: "\F3B1"; }
.mdi-numeric-5-box-outline:before {
  content: "\F3B2"; }
.mdi-numeric-6-box:before {
  content: "\F3B3"; }
.mdi-numeric-6-box-multiple-outline:before {
  content: "\F3B4"; }
.mdi-numeric-6-box-outline:before {
  content: "\F3B5"; }
.mdi-numeric-7-box:before {
  content: "\F3B6"; }
.mdi-numeric-7-box-multiple-outline:before {
  content: "\F3B7"; }
.mdi-numeric-7-box-outline:before {
  content: "\F3B8"; }
.mdi-numeric-8-box:before {
  content: "\F3B9"; }
.mdi-numeric-8-box-multiple-outline:before {
  content: "\F3BA"; }
.mdi-numeric-8-box-outline:before {
  content: "\F3BB"; }
.mdi-numeric-9-box:before {
  content: "\F3BC"; }
.mdi-numeric-9-box-multiple-outline:before {
  content: "\F3BD"; }
.mdi-numeric-9-box-outline:before {
  content: "\F3BE"; }
.mdi-numeric-9-plus-box:before {
  content: "\F3BF"; }
.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "\F3C0"; }
.mdi-numeric-9-plus-box-outline:before {
  content: "\F3C1"; }
.mdi-nut:before {
  content: "\F6F7"; }
.mdi-nutrition:before {
  content: "\F3C2"; }
.mdi-oar:before {
  content: "\F67B"; }
.mdi-octagon:before {
  content: "\F3C3"; }
.mdi-octagon-outline:before {
  content: "\F3C4"; }
.mdi-octagram:before {
  content: "\F6F8"; }
.mdi-octagram-outline:before {
  content: "\F774"; }
.mdi-odnoklassniki:before {
  content: "\F3C5"; }
.mdi-office:before {
  content: "\F3C6"; }
.mdi-oil:before {
  content: "\F3C7"; }
.mdi-oil-temperature:before {
  content: "\F3C8"; }
.mdi-omega:before {
  content: "\F3C9"; }
.mdi-onedrive:before {
  content: "\F3CA"; }
.mdi-onenote:before {
  content: "\F746"; }
.mdi-onepassword:before {
  content: "\F880"; }
.mdi-opacity:before {
  content: "\F5CC"; }
.mdi-open-in-app:before {
  content: "\F3CB"; }
.mdi-open-in-new:before {
  content: "\F3CC"; }
.mdi-openid:before {
  content: "\F3CD"; }
.mdi-opera:before {
  content: "\F3CE"; }
.mdi-orbit:before {
  content: "\F018"; }
.mdi-ornament:before {
  content: "\F3CF"; }
.mdi-ornament-variant:before {
  content: "\F3D0"; }
.mdi-owl:before {
  content: "\F3D2"; }
.mdi-package:before {
  content: "\F3D3"; }
.mdi-package-down:before {
  content: "\F3D4"; }
.mdi-package-up:before {
  content: "\F3D5"; }
.mdi-package-variant:before {
  content: "\F3D6"; }
.mdi-package-variant-closed:before {
  content: "\F3D7"; }
.mdi-page-first:before {
  content: "\F600"; }
.mdi-page-last:before {
  content: "\F601"; }
.mdi-page-layout-body:before {
  content: "\F6F9"; }
.mdi-page-layout-footer:before {
  content: "\F6FA"; }
.mdi-page-layout-header:before {
  content: "\F6FB"; }
.mdi-page-layout-sidebar-left:before {
  content: "\F6FC"; }
.mdi-page-layout-sidebar-right:before {
  content: "\F6FD"; }
.mdi-palette:before {
  content: "\F3D8"; }
.mdi-palette-advanced:before {
  content: "\F3D9"; }
.mdi-palette-swatch:before {
  content: "\F8B4"; }
.mdi-panda:before {
  content: "\F3DA"; }
.mdi-pandora:before {
  content: "\F3DB"; }
.mdi-panorama:before {
  content: "\F3DC"; }
.mdi-panorama-fisheye:before {
  content: "\F3DD"; }
.mdi-panorama-horizontal:before {
  content: "\F3DE"; }
.mdi-panorama-vertical:before {
  content: "\F3DF"; }
.mdi-panorama-wide-angle:before {
  content: "\F3E0"; }
.mdi-paper-cut-vertical:before {
  content: "\F3E1"; }
.mdi-paperclip:before {
  content: "\F3E2"; }
.mdi-parking:before {
  content: "\F3E3"; }
.mdi-passport:before {
  content: "\F7E2"; }
.mdi-patreon:before {
  content: "\F881"; }
.mdi-pause:before {
  content: "\F3E4"; }
.mdi-pause-circle:before {
  content: "\F3E5"; }
.mdi-pause-circle-outline:before {
  content: "\F3E6"; }
.mdi-pause-octagon:before {
  content: "\F3E7"; }
.mdi-pause-octagon-outline:before {
  content: "\F3E8"; }
.mdi-paw:before {
  content: "\F3E9"; }
.mdi-paw-off:before {
  content: "\F657"; }
.mdi-paypal:before {
  content: "\F882"; }
.mdi-peace:before {
  content: "\F883"; }
.mdi-pen:before {
  content: "\F3EA"; }
.mdi-pencil:before {
  content: "\F3EB"; }
.mdi-pencil-box:before {
  content: "\F3EC"; }
.mdi-pencil-box-outline:before {
  content: "\F3ED"; }
.mdi-pencil-circle:before {
  content: "\F6FE"; }
.mdi-pencil-circle-outline:before {
  content: "\F775"; }
.mdi-pencil-lock:before {
  content: "\F3EE"; }
.mdi-pencil-off:before {
  content: "\F3EF"; }
.mdi-pentagon:before {
  content: "\F6FF"; }
.mdi-pentagon-outline:before {
  content: "\F700"; }
.mdi-percent:before {
  content: "\F3F0"; }
.mdi-periodic-table:before {
  content: "\F8B5"; }
.mdi-periodic-table-co2:before {
  content: "\F7E3"; }
.mdi-periscope:before {
  content: "\F747"; }
.mdi-pharmacy:before {
  content: "\F3F1"; }
.mdi-phone:before {
  content: "\F3F2"; }
.mdi-phone-bluetooth:before {
  content: "\F3F3"; }
.mdi-phone-classic:before {
  content: "\F602"; }
.mdi-phone-forward:before {
  content: "\F3F4"; }
.mdi-phone-hangup:before {
  content: "\F3F5"; }
.mdi-phone-in-talk:before {
  content: "\F3F6"; }
.mdi-phone-incoming:before {
  content: "\F3F7"; }
.mdi-phone-locked:before {
  content: "\F3F8"; }
.mdi-phone-log:before {
  content: "\F3F9"; }
.mdi-phone-minus:before {
  content: "\F658"; }
.mdi-phone-missed:before {
  content: "\F3FA"; }
.mdi-phone-outgoing:before {
  content: "\F3FB"; }
.mdi-phone-paused:before {
  content: "\F3FC"; }
.mdi-phone-plus:before {
  content: "\F659"; }
.mdi-phone-return:before {
  content: "\F82E"; }
.mdi-phone-rotate-landscape:before {
  content: "\F884"; }
.mdi-phone-rotate-portrait:before {
  content: "\F885"; }
.mdi-phone-settings:before {
  content: "\F3FD"; }
.mdi-phone-voip:before {
  content: "\F3FE"; }
.mdi-pi:before {
  content: "\F3FF"; }
.mdi-pi-box:before {
  content: "\F400"; }
.mdi-piano:before {
  content: "\F67C"; }
.mdi-pickaxe:before {
  content: "\F8B6"; }
.mdi-pier:before {
  content: "\F886"; }
.mdi-pier-crane:before {
  content: "\F887"; }
.mdi-pig:before {
  content: "\F401"; }
.mdi-pill:before {
  content: "\F402"; }
.mdi-pillar:before {
  content: "\F701"; }
.mdi-pin:before {
  content: "\F403"; }
.mdi-pin-off:before {
  content: "\F404"; }
.mdi-pine-tree:before {
  content: "\F405"; }
.mdi-pine-tree-box:before {
  content: "\F406"; }
.mdi-pinterest:before {
  content: "\F407"; }
.mdi-pinterest-box:before {
  content: "\F408"; }
.mdi-pipe:before {
  content: "\F7E4"; }
.mdi-pipe-disconnected:before {
  content: "\F7E5"; }
.mdi-pipe-leak:before {
  content: "\F888"; }
.mdi-pistol:before {
  content: "\F702"; }
.mdi-piston:before {
  content: "\F889"; }
.mdi-pizza:before {
  content: "\F409"; }
.mdi-plane-shield:before {
  content: "\F6BA"; }
.mdi-play:before {
  content: "\F40A"; }
.mdi-play-box-outline:before {
  content: "\F40B"; }
.mdi-play-circle:before {
  content: "\F40C"; }
.mdi-play-circle-outline:before {
  content: "\F40D"; }
.mdi-play-network:before {
  content: "\F88A"; }
.mdi-play-pause:before {
  content: "\F40E"; }
.mdi-play-protected-content:before {
  content: "\F40F"; }
.mdi-playlist-check:before {
  content: "\F5C7"; }
.mdi-playlist-minus:before {
  content: "\F410"; }
.mdi-playlist-play:before {
  content: "\F411"; }
.mdi-playlist-plus:before {
  content: "\F412"; }
.mdi-playlist-remove:before {
  content: "\F413"; }
.mdi-playstation:before {
  content: "\F414"; }
.mdi-plex:before {
  content: "\F6B9"; }
.mdi-plus:before {
  content: "\F415"; }
.mdi-plus-box:before {
  content: "\F416"; }
.mdi-plus-box-outline:before {
  content: "\F703"; }
.mdi-plus-circle:before {
  content: "\F417"; }
.mdi-plus-circle-multiple-outline:before {
  content: "\F418"; }
.mdi-plus-circle-outline:before {
  content: "\F419"; }
.mdi-plus-network:before {
  content: "\F41A"; }
.mdi-plus-one:before {
  content: "\F41B"; }
.mdi-plus-outline:before {
  content: "\F704"; }
.mdi-pocket:before {
  content: "\F41C"; }
.mdi-pokeball:before {
  content: "\F41D"; }
.mdi-poker-chip:before {
  content: "\F82F"; }
.mdi-polaroid:before {
  content: "\F41E"; }
.mdi-poll:before {
  content: "\F41F"; }
.mdi-poll-box:before {
  content: "\F420"; }
.mdi-polymer:before {
  content: "\F421"; }
.mdi-pool:before {
  content: "\F606"; }
.mdi-popcorn:before {
  content: "\F422"; }
.mdi-pot:before {
  content: "\F65A"; }
.mdi-pot-mix:before {
  content: "\F65B"; }
.mdi-pound:before {
  content: "\F423"; }
.mdi-pound-box:before {
  content: "\F424"; }
.mdi-power:before {
  content: "\F425"; }
.mdi-power-plug:before {
  content: "\F6A4"; }
.mdi-power-plug-off:before {
  content: "\F6A5"; }
.mdi-power-settings:before {
  content: "\F426"; }
.mdi-power-socket:before {
  content: "\F427"; }
.mdi-power-socket-eu:before {
  content: "\F7E6"; }
.mdi-power-socket-uk:before {
  content: "\F7E7"; }
.mdi-power-socket-us:before {
  content: "\F7E8"; }
.mdi-prescription:before {
  content: "\F705"; }
.mdi-presentation:before {
  content: "\F428"; }
.mdi-presentation-play:before {
  content: "\F429"; }
.mdi-printer:before {
  content: "\F42A"; }
.mdi-printer-3d:before {
  content: "\F42B"; }
.mdi-printer-alert:before {
  content: "\F42C"; }
.mdi-printer-settings:before {
  content: "\F706"; }
.mdi-priority-high:before {
  content: "\F603"; }
.mdi-priority-low:before {
  content: "\F604"; }
.mdi-professional-hexagon:before {
  content: "\F42D"; }
.mdi-projector:before {
  content: "\F42E"; }
.mdi-projector-screen:before {
  content: "\F42F"; }
.mdi-publish:before {
  content: "\F6A6"; }
.mdi-pulse:before {
  content: "\F430"; }
.mdi-puzzle:before {
  content: "\F431"; }
.mdi-qqchat:before {
  content: "\F605"; }
.mdi-qrcode:before {
  content: "\F432"; }
.mdi-qrcode-edit:before {
  content: "\F8B7"; }
.mdi-qrcode-scan:before {
  content: "\F433"; }
.mdi-quadcopter:before {
  content: "\F434"; }
.mdi-quality-high:before {
  content: "\F435"; }
.mdi-quicktime:before {
  content: "\F436"; }
.mdi-radar:before {
  content: "\F437"; }
.mdi-radiator:before {
  content: "\F438"; }
.mdi-radio:before {
  content: "\F439"; }
.mdi-radio-handheld:before {
  content: "\F43A"; }
.mdi-radio-tower:before {
  content: "\F43B"; }
.mdi-radioactive:before {
  content: "\F43C"; }
.mdi-radiobox-blank:before {
  content: "\F43D"; }
.mdi-radiobox-marked:before {
  content: "\F43E"; }
.mdi-raspberrypi:before {
  content: "\F43F"; }
.mdi-ray-end:before {
  content: "\F440"; }
.mdi-ray-end-arrow:before {
  content: "\F441"; }
.mdi-ray-start:before {
  content: "\F442"; }
.mdi-ray-start-arrow:before {
  content: "\F443"; }
.mdi-ray-start-end:before {
  content: "\F444"; }
.mdi-ray-vertex:before {
  content: "\F445"; }
.mdi-react:before {
  content: "\F707"; }
.mdi-read:before {
  content: "\F447"; }
.mdi-receipt:before {
  content: "\F449"; }
.mdi-record:before {
  content: "\F44A"; }
.mdi-record-rec:before {
  content: "\F44B"; }
.mdi-recycle:before {
  content: "\F44C"; }
.mdi-reddit:before {
  content: "\F44D"; }
.mdi-redo:before {
  content: "\F44E"; }
.mdi-redo-variant:before {
  content: "\F44F"; }
.mdi-refresh:before {
  content: "\F450"; }
.mdi-regex:before {
  content: "\F451"; }
.mdi-relative-scale:before {
  content: "\F452"; }
.mdi-reload:before {
  content: "\F453"; }
.mdi-reminder:before {
  content: "\F88B"; }
.mdi-remote:before {
  content: "\F454"; }
.mdi-remote-desktop:before {
  content: "\F8B8"; }
.mdi-rename-box:before {
  content: "\F455"; }
.mdi-reorder-horizontal:before {
  content: "\F687"; }
.mdi-reorder-vertical:before {
  content: "\F688"; }
.mdi-repeat:before {
  content: "\F456"; }
.mdi-repeat-off:before {
  content: "\F457"; }
.mdi-repeat-once:before {
  content: "\F458"; }
.mdi-replay:before {
  content: "\F459"; }
.mdi-reply:before {
  content: "\F45A"; }
.mdi-reply-all:before {
  content: "\F45B"; }
.mdi-reproduction:before {
  content: "\F45C"; }
.mdi-resize-bottom-right:before {
  content: "\F45D"; }
.mdi-responsive:before {
  content: "\F45E"; }
.mdi-restart:before {
  content: "\F708"; }
.mdi-restore:before {
  content: "\F6A7"; }
.mdi-rewind:before {
  content: "\F45F"; }
.mdi-rewind-outline:before {
  content: "\F709"; }
.mdi-rhombus:before {
  content: "\F70A"; }
.mdi-rhombus-outline:before {
  content: "\F70B"; }
.mdi-ribbon:before {
  content: "\F460"; }
.mdi-rice:before {
  content: "\F7E9"; }
.mdi-ring:before {
  content: "\F7EA"; }
.mdi-road:before {
  content: "\F461"; }
.mdi-road-variant:before {
  content: "\F462"; }
.mdi-robot:before {
  content: "\F6A8"; }
.mdi-rocket:before {
  content: "\F463"; }
.mdi-room-service:before {
  content: "\F88C"; }
.mdi-roomba:before {
  content: "\F70C"; }
.mdi-rotate-3d:before {
  content: "\F464"; }
.mdi-rotate-left:before {
  content: "\F465"; }
.mdi-rotate-left-variant:before {
  content: "\F466"; }
.mdi-rotate-right:before {
  content: "\F467"; }
.mdi-rotate-right-variant:before {
  content: "\F468"; }
.mdi-rounded-corner:before {
  content: "\F607"; }
.mdi-router-wireless:before {
  content: "\F469"; }
.mdi-routes:before {
  content: "\F46A"; }
.mdi-rowing:before {
  content: "\F608"; }
.mdi-rss:before {
  content: "\F46B"; }
.mdi-rss-box:before {
  content: "\F46C"; }
.mdi-ruler:before {
  content: "\F46D"; }
.mdi-run:before {
  content: "\F70D"; }
.mdi-run-fast:before {
  content: "\F46E"; }
.mdi-sale:before {
  content: "\F46F"; }
.mdi-salesforce:before {
  content: "\F88D"; }
.mdi-sass:before {
  content: "\F7EB"; }
.mdi-satellite:before {
  content: "\F470"; }
.mdi-satellite-variant:before {
  content: "\F471"; }
.mdi-sausage:before {
  content: "\F8B9"; }
.mdi-saxophone:before {
  content: "\F609"; }
.mdi-scale:before {
  content: "\F472"; }
.mdi-scale-balance:before {
  content: "\F5D1"; }
.mdi-scale-bathroom:before {
  content: "\F473"; }
.mdi-scanner:before {
  content: "\F6AA"; }
.mdi-school:before {
  content: "\F474"; }
.mdi-screen-rotation:before {
  content: "\F475"; }
.mdi-screen-rotation-lock:before {
  content: "\F476"; }
.mdi-screwdriver:before {
  content: "\F477"; }
.mdi-script:before {
  content: "\F478"; }
.mdi-sd:before {
  content: "\F479"; }
.mdi-seal:before {
  content: "\F47A"; }
.mdi-search-web:before {
  content: "\F70E"; }
.mdi-seat-flat:before {
  content: "\F47B"; }
.mdi-seat-flat-angled:before {
  content: "\F47C"; }
.mdi-seat-individual-suite:before {
  content: "\F47D"; }
.mdi-seat-legroom-extra:before {
  content: "\F47E"; }
.mdi-seat-legroom-normal:before {
  content: "\F47F"; }
.mdi-seat-legroom-reduced:before {
  content: "\F480"; }
.mdi-seat-recline-extra:before {
  content: "\F481"; }
.mdi-seat-recline-normal:before {
  content: "\F482"; }
.mdi-security:before {
  content: "\F483"; }
.mdi-security-account:before {
  content: "\F88E"; }
.mdi-security-home:before {
  content: "\F689"; }
.mdi-security-network:before {
  content: "\F484"; }
.mdi-select:before {
  content: "\F485"; }
.mdi-select-all:before {
  content: "\F486"; }
.mdi-select-inverse:before {
  content: "\F487"; }
.mdi-select-off:before {
  content: "\F488"; }
.mdi-selection:before {
  content: "\F489"; }
.mdi-selection-off:before {
  content: "\F776"; }
.mdi-send:before {
  content: "\F48A"; }
.mdi-send-secure:before {
  content: "\F7EC"; }
.mdi-serial-port:before {
  content: "\F65C"; }
.mdi-server:before {
  content: "\F48B"; }
.mdi-server-minus:before {
  content: "\F48C"; }
.mdi-server-network:before {
  content: "\F48D"; }
.mdi-server-network-off:before {
  content: "\F48E"; }
.mdi-server-off:before {
  content: "\F48F"; }
.mdi-server-plus:before {
  content: "\F490"; }
.mdi-server-remove:before {
  content: "\F491"; }
.mdi-server-security:before {
  content: "\F492"; }
.mdi-set-all:before {
  content: "\F777"; }
.mdi-set-center:before {
  content: "\F778"; }
.mdi-set-center-right:before {
  content: "\F779"; }
.mdi-set-left:before {
  content: "\F77A"; }
.mdi-set-left-center:before {
  content: "\F77B"; }
.mdi-set-left-right:before {
  content: "\F77C"; }
.mdi-set-none:before {
  content: "\F77D"; }
.mdi-set-right:before {
  content: "\F77E"; }
.mdi-settings:before {
  content: "\F493"; }
.mdi-settings-box:before {
  content: "\F494"; }
.mdi-settings-outline:before {
  content: "\F8BA"; }
.mdi-shape:before {
  content: "\F830"; }
.mdi-shape-circle-plus:before {
  content: "\F65D"; }
.mdi-shape-outline:before {
  content: "\F831"; }
.mdi-shape-plus:before {
  content: "\F495"; }
.mdi-shape-polygon-plus:before {
  content: "\F65E"; }
.mdi-shape-rectangle-plus:before {
  content: "\F65F"; }
.mdi-shape-square-plus:before {
  content: "\F660"; }
.mdi-share:before {
  content: "\F496"; }
.mdi-share-variant:before {
  content: "\F497"; }
.mdi-shield:before {
  content: "\F498"; }
.mdi-shield-half-full:before {
  content: "\F77F"; }
.mdi-shield-outline:before {
  content: "\F499"; }
.mdi-ship-wheel:before {
  content: "\F832"; }
.mdi-shopping:before {
  content: "\F49A"; }
.mdi-shopping-music:before {
  content: "\F49B"; }
.mdi-shovel:before {
  content: "\F70F"; }
.mdi-shovel-off:before {
  content: "\F710"; }
.mdi-shredder:before {
  content: "\F49C"; }
.mdi-shuffle:before {
  content: "\F49D"; }
.mdi-shuffle-disabled:before {
  content: "\F49E"; }
.mdi-shuffle-variant:before {
  content: "\F49F"; }
.mdi-sigma:before {
  content: "\F4A0"; }
.mdi-sigma-lower:before {
  content: "\F62B"; }
.mdi-sign-caution:before {
  content: "\F4A1"; }
.mdi-sign-direction:before {
  content: "\F780"; }
.mdi-sign-text:before {
  content: "\F781"; }
.mdi-signal:before {
  content: "\F4A2"; }
.mdi-signal-2g:before {
  content: "\F711"; }
.mdi-signal-3g:before {
  content: "\F712"; }
.mdi-signal-4g:before {
  content: "\F713"; }
.mdi-signal-cellular-1:before {
  content: "\F8BB"; }
.mdi-signal-cellular-2:before {
  content: "\F8BC"; }
.mdi-signal-cellular-3:before {
  content: "\F8BD"; }
.mdi-signal-cellular-outline:before {
  content: "\F8BE"; }
.mdi-signal-hspa:before {
  content: "\F714"; }
.mdi-signal-hspa-plus:before {
  content: "\F715"; }
.mdi-signal-off:before {
  content: "\F782"; }
.mdi-signal-variant:before {
  content: "\F60A"; }
.mdi-silverware:before {
  content: "\F4A3"; }
.mdi-silverware-fork:before {
  content: "\F4A4"; }
.mdi-silverware-spoon:before {
  content: "\F4A5"; }
.mdi-silverware-variant:before {
  content: "\F4A6"; }
.mdi-sim:before {
  content: "\F4A7"; }
.mdi-sim-alert:before {
  content: "\F4A8"; }
.mdi-sim-off:before {
  content: "\F4A9"; }
.mdi-sitemap:before {
  content: "\F4AA"; }
.mdi-skip-backward:before {
  content: "\F4AB"; }
.mdi-skip-forward:before {
  content: "\F4AC"; }
.mdi-skip-next:before {
  content: "\F4AD"; }
.mdi-skip-next-circle:before {
  content: "\F661"; }
.mdi-skip-next-circle-outline:before {
  content: "\F662"; }
.mdi-skip-previous:before {
  content: "\F4AE"; }
.mdi-skip-previous-circle:before {
  content: "\F663"; }
.mdi-skip-previous-circle-outline:before {
  content: "\F664"; }
.mdi-skull:before {
  content: "\F68B"; }
.mdi-skype:before {
  content: "\F4AF"; }
.mdi-skype-business:before {
  content: "\F4B0"; }
.mdi-slack:before {
  content: "\F4B1"; }
.mdi-sleep:before {
  content: "\F4B2"; }
.mdi-sleep-off:before {
  content: "\F4B3"; }
.mdi-smoking:before {
  content: "\F4B4"; }
.mdi-smoking-off:before {
  content: "\F4B5"; }
.mdi-snapchat:before {
  content: "\F4B6"; }
.mdi-snowflake:before {
  content: "\F716"; }
.mdi-snowman:before {
  content: "\F4B7"; }
.mdi-soccer:before {
  content: "\F4B8"; }
.mdi-soccer-field:before {
  content: "\F833"; }
.mdi-sofa:before {
  content: "\F4B9"; }
.mdi-solid:before {
  content: "\F68C"; }
.mdi-sort:before {
  content: "\F4BA"; }
.mdi-sort-alphabetical:before {
  content: "\F4BB"; }
.mdi-sort-ascending:before {
  content: "\F4BC"; }
.mdi-sort-descending:before {
  content: "\F4BD"; }
.mdi-sort-numeric:before {
  content: "\F4BE"; }
.mdi-sort-variant:before {
  content: "\F4BF"; }
.mdi-soundcloud:before {
  content: "\F4C0"; }
.mdi-source-branch:before {
  content: "\F62C"; }
.mdi-source-commit:before {
  content: "\F717"; }
.mdi-source-commit-end:before {
  content: "\F718"; }
.mdi-source-commit-end-local:before {
  content: "\F719"; }
.mdi-source-commit-local:before {
  content: "\F71A"; }
.mdi-source-commit-next-local:before {
  content: "\F71B"; }
.mdi-source-commit-start:before {
  content: "\F71C"; }
.mdi-source-commit-start-next-local:before {
  content: "\F71D"; }
.mdi-source-fork:before {
  content: "\F4C1"; }
.mdi-source-merge:before {
  content: "\F62D"; }
.mdi-source-pull:before {
  content: "\F4C2"; }
.mdi-soy-sauce:before {
  content: "\F7ED"; }
.mdi-speaker:before {
  content: "\F4C3"; }
.mdi-speaker-off:before {
  content: "\F4C4"; }
.mdi-speaker-wireless:before {
  content: "\F71E"; }
.mdi-speedometer:before {
  content: "\F4C5"; }
.mdi-spellcheck:before {
  content: "\F4C6"; }
.mdi-spotify:before {
  content: "\F4C7"; }
.mdi-spotlight:before {
  content: "\F4C8"; }
.mdi-spotlight-beam:before {
  content: "\F4C9"; }
.mdi-spray:before {
  content: "\F665"; }
.mdi-square:before {
  content: "\F763"; }
.mdi-square-inc:before {
  content: "\F4CA"; }
.mdi-square-inc-cash:before {
  content: "\F4CB"; }
.mdi-square-outline:before {
  content: "\F762"; }
.mdi-square-root:before {
  content: "\F783"; }
.mdi-ssh:before {
  content: "\F8BF"; }
.mdi-stack-overflow:before {
  content: "\F4CC"; }
.mdi-stackexchange:before {
  content: "\F60B"; }
.mdi-stadium:before {
  content: "\F71F"; }
.mdi-stairs:before {
  content: "\F4CD"; }
.mdi-standard-definition:before {
  content: "\F7EE"; }
.mdi-star:before {
  content: "\F4CE"; }
.mdi-star-circle:before {
  content: "\F4CF"; }
.mdi-star-half:before {
  content: "\F4D0"; }
.mdi-star-off:before {
  content: "\F4D1"; }
.mdi-star-outline:before {
  content: "\F4D2"; }
.mdi-steam:before {
  content: "\F4D3"; }
.mdi-steering:before {
  content: "\F4D4"; }
.mdi-step-backward:before {
  content: "\F4D5"; }
.mdi-step-backward-2:before {
  content: "\F4D6"; }
.mdi-step-forward:before {
  content: "\F4D7"; }
.mdi-step-forward-2:before {
  content: "\F4D8"; }
.mdi-stethoscope:before {
  content: "\F4D9"; }
.mdi-sticker:before {
  content: "\F5D0"; }
.mdi-sticker-emoji:before {
  content: "\F784"; }
.mdi-stocking:before {
  content: "\F4DA"; }
.mdi-stop:before {
  content: "\F4DB"; }
.mdi-stop-circle:before {
  content: "\F666"; }
.mdi-stop-circle-outline:before {
  content: "\F667"; }
.mdi-store:before {
  content: "\F4DC"; }
.mdi-store-24-hour:before {
  content: "\F4DD"; }
.mdi-stove:before {
  content: "\F4DE"; }
.mdi-subdirectory-arrow-left:before {
  content: "\F60C"; }
.mdi-subdirectory-arrow-right:before {
  content: "\F60D"; }
.mdi-subway:before {
  content: "\F6AB"; }
.mdi-subway-variant:before {
  content: "\F4DF"; }
.mdi-summit:before {
  content: "\F785"; }
.mdi-sunglasses:before {
  content: "\F4E0"; }
.mdi-surround-sound:before {
  content: "\F5C5"; }
.mdi-surround-sound-2-0:before {
  content: "\F7EF"; }
.mdi-surround-sound-3-1:before {
  content: "\F7F0"; }
.mdi-surround-sound-5-1:before {
  content: "\F7F1"; }
.mdi-surround-sound-7-1:before {
  content: "\F7F2"; }
.mdi-svg:before {
  content: "\F720"; }
.mdi-swap-horizontal:before {
  content: "\F4E1"; }
.mdi-swap-horizontal-variant:before {
  content: "\F8C0"; }
.mdi-swap-vertical:before {
  content: "\F4E2"; }
.mdi-swap-vertical-variant:before {
  content: "\F8C1"; }
.mdi-swim:before {
  content: "\F4E3"; }
.mdi-switch:before {
  content: "\F4E4"; }
.mdi-sword:before {
  content: "\F4E5"; }
.mdi-sword-cross:before {
  content: "\F786"; }
.mdi-sync:before {
  content: "\F4E6"; }
.mdi-sync-alert:before {
  content: "\F4E7"; }
.mdi-sync-off:before {
  content: "\F4E8"; }
.mdi-tab:before {
  content: "\F4E9"; }
.mdi-tab-plus:before {
  content: "\F75B"; }
.mdi-tab-unselected:before {
  content: "\F4EA"; }
.mdi-table:before {
  content: "\F4EB"; }
.mdi-table-column:before {
  content: "\F834"; }
.mdi-table-column-plus-after:before {
  content: "\F4EC"; }
.mdi-table-column-plus-before:before {
  content: "\F4ED"; }
.mdi-table-column-remove:before {
  content: "\F4EE"; }
.mdi-table-column-width:before {
  content: "\F4EF"; }
.mdi-table-edit:before {
  content: "\F4F0"; }
.mdi-table-large:before {
  content: "\F4F1"; }
.mdi-table-of-contents:before {
  content: "\F835"; }
.mdi-table-row:before {
  content: "\F836"; }
.mdi-table-row-height:before {
  content: "\F4F2"; }
.mdi-table-row-plus-after:before {
  content: "\F4F3"; }
.mdi-table-row-plus-before:before {
  content: "\F4F4"; }
.mdi-table-row-remove:before {
  content: "\F4F5"; }
.mdi-table-settings:before {
  content: "\F837"; }
.mdi-tablet:before {
  content: "\F4F6"; }
.mdi-tablet-android:before {
  content: "\F4F7"; }
.mdi-tablet-ipad:before {
  content: "\F4F8"; }
.mdi-taco:before {
  content: "\F761"; }
.mdi-tag:before {
  content: "\F4F9"; }
.mdi-tag-faces:before {
  content: "\F4FA"; }
.mdi-tag-heart:before {
  content: "\F68A"; }
.mdi-tag-multiple:before {
  content: "\F4FB"; }
.mdi-tag-outline:before {
  content: "\F4FC"; }
.mdi-tag-plus:before {
  content: "\F721"; }
.mdi-tag-remove:before {
  content: "\F722"; }
.mdi-tag-text-outline:before {
  content: "\F4FD"; }
.mdi-target:before {
  content: "\F4FE"; }
.mdi-taxi:before {
  content: "\F4FF"; }
.mdi-teach:before {
  content: "\F88F"; }
.mdi-teamviewer:before {
  content: "\F500"; }
.mdi-telegram:before {
  content: "\F501"; }
.mdi-television:before {
  content: "\F502"; }
.mdi-television-box:before {
  content: "\F838"; }
.mdi-television-classic:before {
  content: "\F7F3"; }
.mdi-television-classic-off:before {
  content: "\F839"; }
.mdi-television-guide:before {
  content: "\F503"; }
.mdi-television-off:before {
  content: "\F83A"; }
.mdi-temperature-celsius:before {
  content: "\F504"; }
.mdi-temperature-fahrenheit:before {
  content: "\F505"; }
.mdi-temperature-kelvin:before {
  content: "\F506"; }
.mdi-tennis:before {
  content: "\F507"; }
.mdi-tent:before {
  content: "\F508"; }
.mdi-terrain:before {
  content: "\F509"; }
.mdi-test-tube:before {
  content: "\F668"; }
.mdi-text-shadow:before {
  content: "\F669"; }
.mdi-text-to-speech:before {
  content: "\F50A"; }
.mdi-text-to-speech-off:before {
  content: "\F50B"; }
.mdi-textbox:before {
  content: "\F60E"; }
.mdi-textbox-password:before {
  content: "\F7F4"; }
.mdi-texture:before {
  content: "\F50C"; }
.mdi-theater:before {
  content: "\F50D"; }
.mdi-theme-light-dark:before {
  content: "\F50E"; }
.mdi-thermometer:before {
  content: "\F50F"; }
.mdi-thermometer-lines:before {
  content: "\F510"; }
.mdi-thermostat-box:before {
  content: "\F890"; }
.mdi-thought-bubble:before {
  content: "\F7F5"; }
.mdi-thought-bubble-outline:before {
  content: "\F7F6"; }
.mdi-thumb-down:before {
  content: "\F511"; }
.mdi-thumb-down-outline:before {
  content: "\F512"; }
.mdi-thumb-up:before {
  content: "\F513"; }
.mdi-thumb-up-outline:before {
  content: "\F514"; }
.mdi-thumbs-up-down:before {
  content: "\F515"; }
.mdi-ticket:before {
  content: "\F516"; }
.mdi-ticket-account:before {
  content: "\F517"; }
.mdi-ticket-confirmation:before {
  content: "\F518"; }
.mdi-ticket-percent:before {
  content: "\F723"; }
.mdi-tie:before {
  content: "\F519"; }
.mdi-tilde:before {
  content: "\F724"; }
.mdi-timelapse:before {
  content: "\F51A"; }
.mdi-timer:before {
  content: "\F51B"; }
.mdi-timer-10:before {
  content: "\F51C"; }
.mdi-timer-3:before {
  content: "\F51D"; }
.mdi-timer-off:before {
  content: "\F51E"; }
.mdi-timer-sand:before {
  content: "\F51F"; }
.mdi-timer-sand-empty:before {
  content: "\F6AC"; }
.mdi-timer-sand-full:before {
  content: "\F78B"; }
.mdi-timetable:before {
  content: "\F520"; }
.mdi-toggle-switch:before {
  content: "\F521"; }
.mdi-toggle-switch-off:before {
  content: "\F522"; }
.mdi-tooltip:before {
  content: "\F523"; }
.mdi-tooltip-edit:before {
  content: "\F524"; }
.mdi-tooltip-image:before {
  content: "\F525"; }
.mdi-tooltip-outline:before {
  content: "\F526"; }
.mdi-tooltip-outline-plus:before {
  content: "\F527"; }
.mdi-tooltip-text:before {
  content: "\F528"; }
.mdi-tooth:before {
  content: "\F8C2"; }
.mdi-tooth-outline:before {
  content: "\F529"; }
.mdi-tor:before {
  content: "\F52A"; }
.mdi-tower-beach:before {
  content: "\F680"; }
.mdi-tower-fire:before {
  content: "\F681"; }
.mdi-towing:before {
  content: "\F83B"; }
.mdi-trackpad:before {
  content: "\F7F7"; }
.mdi-tractor:before {
  content: "\F891"; }
.mdi-traffic-light:before {
  content: "\F52B"; }
.mdi-train:before {
  content: "\F52C"; }
.mdi-train-variant:before {
  content: "\F8C3"; }
.mdi-tram:before {
  content: "\F52D"; }
.mdi-transcribe:before {
  content: "\F52E"; }
.mdi-transcribe-close:before {
  content: "\F52F"; }
.mdi-transfer:before {
  content: "\F530"; }
.mdi-transit-transfer:before {
  content: "\F6AD"; }
.mdi-translate:before {
  content: "\F5CA"; }
.mdi-treasure-chest:before {
  content: "\F725"; }
.mdi-tree:before {
  content: "\F531"; }
.mdi-trello:before {
  content: "\F532"; }
.mdi-trending-down:before {
  content: "\F533"; }
.mdi-trending-neutral:before {
  content: "\F534"; }
.mdi-trending-up:before {
  content: "\F535"; }
.mdi-triangle:before {
  content: "\F536"; }
.mdi-triangle-outline:before {
  content: "\F537"; }
.mdi-trophy:before {
  content: "\F538"; }
.mdi-trophy-award:before {
  content: "\F539"; }
.mdi-trophy-outline:before {
  content: "\F53A"; }
.mdi-trophy-variant:before {
  content: "\F53B"; }
.mdi-trophy-variant-outline:before {
  content: "\F53C"; }
.mdi-truck:before {
  content: "\F53D"; }
.mdi-truck-delivery:before {
  content: "\F53E"; }
.mdi-truck-fast:before {
  content: "\F787"; }
.mdi-truck-trailer:before {
  content: "\F726"; }
.mdi-tshirt-crew:before {
  content: "\F53F"; }
.mdi-tshirt-v:before {
  content: "\F540"; }
.mdi-tumblr:before {
  content: "\F541"; }
.mdi-tumblr-reblog:before {
  content: "\F542"; }
.mdi-tune:before {
  content: "\F62E"; }
.mdi-tune-vertical:before {
  content: "\F66A"; }
.mdi-twitch:before {
  content: "\F543"; }
.mdi-twitter:before {
  content: "\F544"; }
.mdi-twitter-box:before {
  content: "\F545"; }
.mdi-twitter-circle:before {
  content: "\F546"; }
.mdi-twitter-retweet:before {
  content: "\F547"; }
.mdi-uber:before {
  content: "\F748"; }
.mdi-ubuntu:before {
  content: "\F548"; }
.mdi-ultra-high-definition:before {
  content: "\F7F8"; }
.mdi-umbraco:before {
  content: "\F549"; }
.mdi-umbrella:before {
  content: "\F54A"; }
.mdi-umbrella-outline:before {
  content: "\F54B"; }
.mdi-undo:before {
  content: "\F54C"; }
.mdi-undo-variant:before {
  content: "\F54D"; }
.mdi-unfold-less-horizontal:before {
  content: "\F54E"; }
.mdi-unfold-less-vertical:before {
  content: "\F75F"; }
.mdi-unfold-more-horizontal:before {
  content: "\F54F"; }
.mdi-unfold-more-vertical:before {
  content: "\F760"; }
.mdi-ungroup:before {
  content: "\F550"; }
.mdi-unity:before {
  content: "\F6AE"; }
.mdi-untappd:before {
  content: "\F551"; }
.mdi-update:before {
  content: "\F6AF"; }
.mdi-upload:before {
  content: "\F552"; }
.mdi-upload-multiple:before {
  content: "\F83C"; }
.mdi-upload-network:before {
  content: "\F6F5"; }
.mdi-usb:before {
  content: "\F553"; }
.mdi-van-passenger:before {
  content: "\F7F9"; }
.mdi-van-utility:before {
  content: "\F7FA"; }
.mdi-vanish:before {
  content: "\F7FB"; }
.mdi-vector-arrange-above:before {
  content: "\F554"; }
.mdi-vector-arrange-below:before {
  content: "\F555"; }
.mdi-vector-circle:before {
  content: "\F556"; }
.mdi-vector-circle-variant:before {
  content: "\F557"; }
.mdi-vector-combine:before {
  content: "\F558"; }
.mdi-vector-curve:before {
  content: "\F559"; }
.mdi-vector-difference:before {
  content: "\F55A"; }
.mdi-vector-difference-ab:before {
  content: "\F55B"; }
.mdi-vector-difference-ba:before {
  content: "\F55C"; }
.mdi-vector-ellipse:before {
  content: "\F892"; }
.mdi-vector-intersection:before {
  content: "\F55D"; }
.mdi-vector-line:before {
  content: "\F55E"; }
.mdi-vector-point:before {
  content: "\F55F"; }
.mdi-vector-polygon:before {
  content: "\F560"; }
.mdi-vector-polyline:before {
  content: "\F561"; }
.mdi-vector-radius:before {
  content: "\F749"; }
.mdi-vector-rectangle:before {
  content: "\F5C6"; }
.mdi-vector-selection:before {
  content: "\F562"; }
.mdi-vector-square:before {
  content: "\F001"; }
.mdi-vector-triangle:before {
  content: "\F563"; }
.mdi-vector-union:before {
  content: "\F564"; }
.mdi-venmo:before {
  content: "\F578"; }
.mdi-verified:before {
  content: "\F565"; }
.mdi-vibrate:before {
  content: "\F566"; }
.mdi-video:before {
  content: "\F567"; }
.mdi-video-3d:before {
  content: "\F7FC"; }
.mdi-video-4k-box:before {
  content: "\F83D"; }
.mdi-video-input-antenna:before {
  content: "\F83E"; }
.mdi-video-input-component:before {
  content: "\F83F"; }
.mdi-video-input-hdmi:before {
  content: "\F840"; }
.mdi-video-input-svideo:before {
  content: "\F841"; }
.mdi-video-off:before {
  content: "\F568"; }
.mdi-video-switch:before {
  content: "\F569"; }
.mdi-view-agenda:before {
  content: "\F56A"; }
.mdi-view-array:before {
  content: "\F56B"; }
.mdi-view-carousel:before {
  content: "\F56C"; }
.mdi-view-column:before {
  content: "\F56D"; }
.mdi-view-dashboard:before {
  content: "\F56E"; }
.mdi-view-dashboard-variant:before {
  content: "\F842"; }
.mdi-view-day:before {
  content: "\F56F"; }
.mdi-view-grid:before {
  content: "\F570"; }
.mdi-view-headline:before {
  content: "\F571"; }
.mdi-view-list:before {
  content: "\F572"; }
.mdi-view-module:before {
  content: "\F573"; }
.mdi-view-parallel:before {
  content: "\F727"; }
.mdi-view-quilt:before {
  content: "\F574"; }
.mdi-view-sequential:before {
  content: "\F728"; }
.mdi-view-stream:before {
  content: "\F575"; }
.mdi-view-week:before {
  content: "\F576"; }
.mdi-vimeo:before {
  content: "\F577"; }
.mdi-violin:before {
  content: "\F60F"; }
.mdi-virtual-reality:before {
  content: "\F893"; }
.mdi-visualstudio:before {
  content: "\F610"; }
.mdi-vk:before {
  content: "\F579"; }
.mdi-vk-box:before {
  content: "\F57A"; }
.mdi-vk-circle:before {
  content: "\F57B"; }
.mdi-vlc:before {
  content: "\F57C"; }
.mdi-voice:before {
  content: "\F5CB"; }
.mdi-voicemail:before {
  content: "\F57D"; }
.mdi-volume-high:before {
  content: "\F57E"; }
.mdi-volume-low:before {
  content: "\F57F"; }
.mdi-volume-medium:before {
  content: "\F580"; }
.mdi-volume-minus:before {
  content: "\F75D"; }
.mdi-volume-mute:before {
  content: "\F75E"; }
.mdi-volume-off:before {
  content: "\F581"; }
.mdi-volume-plus:before {
  content: "\F75C"; }
.mdi-vpn:before {
  content: "\F582"; }
.mdi-vuejs:before {
  content: "\F843"; }
.mdi-walk:before {
  content: "\F583"; }
.mdi-wall:before {
  content: "\F7FD"; }
.mdi-wallet:before {
  content: "\F584"; }
.mdi-wallet-giftcard:before {
  content: "\F585"; }
.mdi-wallet-membership:before {
  content: "\F586"; }
.mdi-wallet-travel:before {
  content: "\F587"; }
.mdi-wan:before {
  content: "\F588"; }
.mdi-washing-machine:before {
  content: "\F729"; }
.mdi-watch:before {
  content: "\F589"; }
.mdi-watch-export:before {
  content: "\F58A"; }
.mdi-watch-export-variant:before {
  content: "\F894"; }
.mdi-watch-import:before {
  content: "\F58B"; }
.mdi-watch-import-variant:before {
  content: "\F895"; }
.mdi-watch-variant:before {
  content: "\F896"; }
.mdi-watch-vibrate:before {
  content: "\F6B0"; }
.mdi-water:before {
  content: "\F58C"; }
.mdi-water-off:before {
  content: "\F58D"; }
.mdi-water-percent:before {
  content: "\F58E"; }
.mdi-water-pump:before {
  content: "\F58F"; }
.mdi-watermark:before {
  content: "\F612"; }
.mdi-waves:before {
  content: "\F78C"; }
.mdi-weather-cloudy:before {
  content: "\F590"; }
.mdi-weather-fog:before {
  content: "\F591"; }
.mdi-weather-hail:before {
  content: "\F592"; }
.mdi-weather-hurricane:before {
  content: "\F897"; }
.mdi-weather-lightning:before {
  content: "\F593"; }
.mdi-weather-lightning-rainy:before {
  content: "\F67D"; }
.mdi-weather-night:before {
  content: "\F594"; }
.mdi-weather-partlycloudy:before {
  content: "\F595"; }
.mdi-weather-pouring:before {
  content: "\F596"; }
.mdi-weather-rainy:before {
  content: "\F597"; }
.mdi-weather-snowy:before {
  content: "\F598"; }
.mdi-weather-snowy-rainy:before {
  content: "\F67E"; }
.mdi-weather-sunny:before {
  content: "\F599"; }
.mdi-weather-sunset:before {
  content: "\F59A"; }
.mdi-weather-sunset-down:before {
  content: "\F59B"; }
.mdi-weather-sunset-up:before {
  content: "\F59C"; }
.mdi-weather-windy:before {
  content: "\F59D"; }
.mdi-weather-windy-variant:before {
  content: "\F59E"; }
.mdi-web:before {
  content: "\F59F"; }
.mdi-webcam:before {
  content: "\F5A0"; }
.mdi-webhook:before {
  content: "\F62F"; }
.mdi-webpack:before {
  content: "\F72A"; }
.mdi-wechat:before {
  content: "\F611"; }
.mdi-weight:before {
  content: "\F5A1"; }
.mdi-weight-kilogram:before {
  content: "\F5A2"; }
.mdi-whatsapp:before {
  content: "\F5A3"; }
.mdi-wheelchair-accessibility:before {
  content: "\F5A4"; }
.mdi-white-balance-auto:before {
  content: "\F5A5"; }
.mdi-white-balance-incandescent:before {
  content: "\F5A6"; }
.mdi-white-balance-iridescent:before {
  content: "\F5A7"; }
.mdi-white-balance-sunny:before {
  content: "\F5A8"; }
.mdi-widgets:before {
  content: "\F72B"; }
.mdi-wifi:before {
  content: "\F5A9"; }
.mdi-wifi-off:before {
  content: "\F5AA"; }
.mdi-wii:before {
  content: "\F5AB"; }
.mdi-wiiu:before {
  content: "\F72C"; }
.mdi-wikipedia:before {
  content: "\F5AC"; }
.mdi-window-close:before {
  content: "\F5AD"; }
.mdi-window-closed:before {
  content: "\F5AE"; }
.mdi-window-maximize:before {
  content: "\F5AF"; }
.mdi-window-minimize:before {
  content: "\F5B0"; }
.mdi-window-open:before {
  content: "\F5B1"; }
.mdi-window-restore:before {
  content: "\F5B2"; }
.mdi-windows:before {
  content: "\F5B3"; }
.mdi-wordpress:before {
  content: "\F5B4"; }
.mdi-worker:before {
  content: "\F5B5"; }
.mdi-wrap:before {
  content: "\F5B6"; }
.mdi-wrench:before {
  content: "\F5B7"; }
.mdi-wunderlist:before {
  content: "\F5B8"; }
.mdi-xamarin:before {
  content: "\F844"; }
.mdi-xamarin-outline:before {
  content: "\F845"; }
.mdi-xaml:before {
  content: "\F673"; }
.mdi-xbox:before {
  content: "\F5B9"; }
.mdi-xbox-controller:before {
  content: "\F5BA"; }
.mdi-xbox-controller-battery-alert:before {
  content: "\F74A"; }
.mdi-xbox-controller-battery-empty:before {
  content: "\F74B"; }
.mdi-xbox-controller-battery-full:before {
  content: "\F74C"; }
.mdi-xbox-controller-battery-low:before {
  content: "\F74D"; }
.mdi-xbox-controller-battery-medium:before {
  content: "\F74E"; }
.mdi-xbox-controller-battery-unknown:before {
  content: "\F74F"; }
.mdi-xbox-controller-off:before {
  content: "\F5BB"; }
.mdi-xda:before {
  content: "\F5BC"; }
.mdi-xing:before {
  content: "\F5BD"; }
.mdi-xing-box:before {
  content: "\F5BE"; }
.mdi-xing-circle:before {
  content: "\F5BF"; }
.mdi-xml:before {
  content: "\F5C0"; }
.mdi-xmpp:before {
  content: "\F7FE"; }
.mdi-yammer:before {
  content: "\F788"; }
.mdi-yeast:before {
  content: "\F5C1"; }
.mdi-yelp:before {
  content: "\F5C2"; }
.mdi-yin-yang:before {
  content: "\F67F"; }
.mdi-youtube-creator-studio:before {
  content: "\F846"; }
.mdi-youtube-gaming:before {
  content: "\F847"; }
.mdi-youtube-play:before {
  content: "\F5C3"; }
.mdi-youtube-tv:before {
  content: "\F448"; }
.mdi-zip-box:before {
  content: "\F5C4"; }
.mdi-blank:before {
  content: "\F68C";
  visibility: hidden; }
.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px; }
.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px; }
.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px; }
.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px; }
.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54); }
.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26); }
.mdi-light:before {
  color: white; }
.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3); }
.mdi-rotate-45 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(45deg);
            transform: scaleX(-1) rotate(45deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: scaleY(-1) rotate(45deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
.mdi-rotate-45:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
.mdi-rotate-90 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: scaleY(-1) rotate(90deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
.mdi-rotate-90:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }
.mdi-rotate-135 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: scaleY(-1) rotate(135deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
.mdi-rotate-135:before {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg); }
.mdi-rotate-180 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(180deg);
            transform: scaleX(-1) rotate(180deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scaleY(-1) rotate(180deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
.mdi-rotate-180:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
.mdi-rotate-225 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(225deg);
            transform: scaleX(-1) rotate(225deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: scaleY(-1) rotate(225deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
.mdi-rotate-225:before {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg); }
.mdi-rotate-270 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(270deg);
            transform: scaleX(-1) rotate(270deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: scaleY(-1) rotate(270deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
.mdi-rotate-270:before {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }
.mdi-rotate-315 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(315deg);
            transform: scaleX(-1) rotate(315deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: scaleY(-1) rotate(315deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */ }
.mdi-rotate-315:before {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg); }
.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-filter: FlipH;
          filter: FlipH;
  -ms-filter: "FlipH"; }
.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -webkit-filter: FlipV;
          filter: FlipV;
  -ms-filter: "FlipV"; }
.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear; }
@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.erro {
  text-align: center;
  padding: 150px;
  background-image: url(/static/image/erro.jpg);
  background-size: cover;
}
.erro i {
    font-size: 90px !important;
    color: #ccc !important;
}
.erro h3 {
    margin-top: 5px !important;
    color: #fff !important;
    font-weight: 300 !important;
    margin-bottom: 20px !important;
}
/* workaround for https://github.com/bootstrap-vue/bootstrap-vue/issues/1560 */
/* source: _input-group.scss */
.input-group > .input-group-prepend > .b-dropdown > .btn,
.input-group > .input-group-append:not(:last-child) > .b-dropdown > .btn,
.input-group > .input-group-append:last-child > .b-dropdown:not(:last-child):not(.dropdown-toggle) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .b-dropdown > .btn,
.input-group > .input-group-prepend:not(:first-child) > .b-dropdown > .btn,
.input-group > .input-group-prepend:first-child > .b-dropdown:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
/* Special styling for type=range and type=color input */
input.form-control[type="range"],
input.form-control[type="color"] {
    height: 2.25rem;
}
input.form-control.form-control-sm[type="range"],
input.form-control.form-control-sm[type="color"] {
    height: 1.9375rem;
}
input.form-control.form-control-lg[type="range"],
input.form-control.form-control-lg[type="color"] {
    height: 3rem;
}
/* Less padding on type=color */
input.form-control[type="color"] {
    padding: 0.25rem 0.25rem;
}
input.form-control.form-control-sm[type="color"] {
    padding: 0.125rem 0.125rem;
}
/* Add support for fixed layout table */
table.b-table.b-table-fixed {
    table-layout: fixed;
}
/* Busy table styling */
table.b-table[aria-busy='false'] {
    opacity: 1;
}
table.b-table[aria-busy='true'] {
    opacity: 0.6;
}
/* Sort styling */
table.b-table > thead > tr > th,
table.b-table > tfoot > tr > th {
    position: relative;
}
table.b-table > thead > tr > th.sorting,
table.b-table > tfoot > tr > th.sorting {
    padding-right: 1.5em;
    cursor: pointer;
}
table.b-table > thead > tr > th.sorting::before,
table.b-table > thead > tr > th.sorting::after,
table.b-table > tfoot > tr > th.sorting::before,
table.b-table > tfoot > tr > th.sorting::after {
    position: absolute;
    bottom: 0;
    display: block;
    opacity: 0.4;
    padding-bottom: inherit;
    font-size: inherit;
    line-height: 180%;
}
table.b-table > thead > tr > th.sorting::before,
table.b-table > tfoot > tr > th.sorting::before {
    right: 0.75em;
    content: '\2191';
}
table.b-table > thead > tr > th.sorting::after,
table.b-table > tfoot > tr > th.sorting::after {
    right: 0.25em;
    content: '\2193';
}
table.b-table > thead > tr > th.sorting_asc::after,
table.b-table > thead > tr > th.sorting_desc::before,
table.b-table > tfoot > tr > th.sorting_asc::after,
table.b-table > tfoot > tr > th.sorting_desc::before {
    opacity: 1;
}
/* Stacked table layout */
/* Derived from http://blog.adrianroselli.com/2017/11/a-responsive-accessible-table.html */
/* Always stacked */
table.b-table.b-table-stacked {
    width: 100%;
}
table.b-table.b-table-stacked,
table.b-table.b-table-stacked > tbody,
table.b-table.b-table-stacked > tbody > tr,
table.b-table.b-table-stacked > tbody > tr > td,
table.b-table.b-table-stacked > tbody > tr > th,
table.b-table.b-table-stacked > caption {
    display: block;
}
/* Hide stuff we can't deal with, or shouldn't show */
table.b-table.b-table-stacked > thead,
table.b-table.b-table-stacked > tfoot,
table.b-table.b-table-stacked > tbody > tr.b-table-top-row,
table.b-table.b-table-stacked > tbody > tr.b-table-bottom-row {
    display: none;
}
/* inter-row top border */
table.b-table.b-table-stacked > tbody > tr > :first-child {
    border-top-width: 0.4rem;
}
/* convert TD/TH contents to "cells". Caveat: child elements become cells! */
table.b-table.b-table-stacked > tbody > tr > [data-label] {
    display: grid;
    grid-template-columns: 40% auto;
    grid-gap: 0.25rem 1rem;
}
/* generate row cell "heading" */
table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
    content: attr(data-label);
    display: inline;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
}
@media all and (max-width: 575.99px) {
    /* Under SM */
    table.b-table.b-table-stacked-sm {
        width: 100%;
    }
    table.b-table.b-table-stacked-sm,
    table.b-table.b-table-stacked-sm > tbody,
    table.b-table.b-table-stacked-sm > tbody > tr,
    table.b-table.b-table-stacked-sm > tbody > tr > td,
    table.b-table.b-table-stacked-sm > tbody > tr > th,
    table.b-table.b-table-stacked-sm > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-sm > thead,
    table.b-table.b-table-stacked-sm > tfoot,
    table.b-table.b-table-stacked-sm > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-sm > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-sm > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-sm > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
@media all and (max-width: 767.99px) {
    /* under MD  */
    table.b-table.b-table-stacked-md {
        width: 100%;
    }
    table.b-table.b-table-stacked-md,
    table.b-table.b-table-stacked-md > tbody,
    table.b-table.b-table-stacked-md > tbody > tr,
    table.b-table.b-table-stacked-md > tbody > tr > td,
    table.b-table.b-table-stacked-md > tbody > tr > th,
    table.b-table.b-table-stacked-md > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-md > thead,
    table.b-table.b-table-stacked-md > tfoot,
    table.b-table.b-table-stacked-md > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-md > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-md > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-md > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
@media all and (max-width: 991.99px) {
    /* under LG  */
    table.b-table.b-table-stacked-lg {
        width: 100%;
    }
    table.b-table.b-table-stacked-lg,
    table.b-table.b-table-stacked-lg > tbody,
    table.b-table.b-table-stacked-lg > tbody > tr,
    table.b-table.b-table-stacked-lg > tbody > tr > td,
    table.b-table.b-table-stacked-lg > tbody > tr > th,
    table.b-table.b-table-stacked-lg > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-lg > thead,
    table.b-table.b-table-stacked-lg > tfoot,
    table.b-table.b-table-stacked-lg > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-lg > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-lg > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-lg > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
@media all and (max-width: 1199.99px) {
    /* under XL  */
    table.b-table.b-table-stacked-xl {
        width: 100%;
    }
    table.b-table.b-table-stacked-xl,
    table.b-table.b-table-stacked-xl > tbody,
    table.b-table.b-table-stacked-xl > tbody > tr,
    table.b-table.b-table-stacked-xl > tbody > tr > td,
    table.b-table.b-table-stacked-xl > tbody > tr > th,
    table.b-table.b-table-stacked-xl > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-xl > thead,
    table.b-table.b-table-stacked-xl > tfoot,
    table.b-table.b-table-stacked-xl > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-xl > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-xl > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-xl > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
/* Details row styling */
table.b-table > tbody > tr.b-table-details > td {
    border-top: none;
}
/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
@-ms-viewport {
  width: device-width;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: .5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
h1, .h1 {
  font-size: 2.5rem;
}
h2, .h2 {
  font-size: 2rem;
}
h3, .h3 {
  font-size: 1.75rem;
}
h4, .h4 {
  font-size: 1.5rem;
}
h5, .h5 {
  font-size: 1.25rem;
}
h6, .h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
small,
.small {
  font-size: 80%;
  font-weight: 400;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014   \A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
          flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
          flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-1 {
  -ms-flex: 0 0 8.333333%;
  -webkit-box-flex: 0;
          flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  -ms-flex: 0 0 16.666667%;
  -webkit-box-flex: 0;
          flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
          flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  -webkit-box-flex: 0;
          flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  -webkit-box-flex: 0;
          flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
          flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -ms-flex: 0 0 58.333333%;
  -webkit-box-flex: 0;
          flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  -webkit-box-flex: 0;
          flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  -ms-flex: 0 0 75%;
  -webkit-box-flex: 0;
          flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  -webkit-box-flex: 0;
          flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  -ms-flex: 0 0 91.666667%;
  -webkit-box-flex: 0;
          flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
          flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
          order: -1;
}
.order-last {
  -ms-flex-order: 13;
  -webkit-box-ordinal-group: 14;
          order: 13;
}
.order-0 {
  -ms-flex-order: 0;
  -webkit-box-ordinal-group: 1;
          order: 0;
}
.order-1 {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
          order: 1;
}
.order-2 {
  -ms-flex-order: 2;
  -webkit-box-ordinal-group: 3;
          order: 2;
}
.order-3 {
  -ms-flex-order: 3;
  -webkit-box-ordinal-group: 4;
          order: 3;
}
.order-4 {
  -ms-flex-order: 4;
  -webkit-box-ordinal-group: 5;
          order: 4;
}
.order-5 {
  -ms-flex-order: 5;
  -webkit-box-ordinal-group: 6;
          order: 5;
}
.order-6 {
  -ms-flex-order: 6;
  -webkit-box-ordinal-group: 7;
          order: 6;
}
.order-7 {
  -ms-flex-order: 7;
  -webkit-box-ordinal-group: 8;
          order: 7;
}
.order-8 {
  -ms-flex-order: 8;
  -webkit-box-ordinal-group: 9;
          order: 8;
}
.order-9 {
  -ms-flex-order: 9;
  -webkit-box-ordinal-group: 10;
          order: 9;
}
.order-10 {
  -ms-flex-order: 10;
  -webkit-box-ordinal-group: 11;
          order: 10;
}
.order-11 {
  -ms-flex-order: 11;
  -webkit-box-ordinal-group: 12;
          order: 11;
}
.order-12 {
  -ms-flex-order: 12;
  -webkit-box-ordinal-group: 13;
          order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table .table {
  background-color: #fff;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}
.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem;
}
.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}
.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-flow: row wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
            align-items: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
    -ms-flex-align: center;
    -webkit-box-align: center;
            align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
            align-items: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
            justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    -webkit-box-align: center;
            align-items: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover, .btn:focus {
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}
.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
          flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
          justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -ms-flex-align: start;
  -webkit-box-align: start;
          align-items: flex-start;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
          align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
          flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}
.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}
.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-appearance: none;
  appearance: none;
}
.custom-range::-webkit-slider-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-appearance: none;
  appearance: none;
}
.custom-range::-moz-range-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  appearance: none;
}
.custom-range::-ms-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.nav {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
          flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
          flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}
.navbar-nav {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
          flex-grow: 1;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row nowrap;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row nowrap;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row nowrap;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row nowrap;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  -ms-flex-flow: row nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
          justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: -webkit-box !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
          flex: 1 1 auto;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 1 0 0%;
    -webkit-box-flex: 1;
            flex: 1 0 0%;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    -webkit-box-flex: 1;
            flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.breadcrumb {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
.media {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: start;
  -webkit-box-align: start;
          align-items: flex-start;
}
.media-body {
  -ms-flex: 1;
  -webkit-box-flex: 1;
          flex: 1;
}
.list-group {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal-dialog-centered {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: start;
  -webkit-box-align: start;
          align-items: flex-start;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
          flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
          justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}
.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}
.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-item {
  position: relative;
  display: none;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
          flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #007bff !important;
}
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #007bff !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: -webkit-box !important;
  display: flex !important;
}
.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: -webkit-inline-box !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  -ms-flex-direction: row !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
          flex-direction: row !important;
}
.flex-column {
  -ms-flex-direction: column !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
          flex-direction: column !important;
}
.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
          flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
          flex-direction: column-reverse !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  -ms-flex: 1 1 auto !important;
  -webkit-box-flex: 1 !important;
          flex: 1 1 auto !important;
}
.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  -webkit-box-flex: 0 !important;
          flex-grow: 0 !important;
}
.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  -webkit-box-flex: 1 !important;
          flex-grow: 1 !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}
.justify-content-start {
  -ms-flex-pack: start !important;
  -webkit-box-pack: start !important;
          justify-content: flex-start !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  -webkit-box-pack: end !important;
          justify-content: flex-end !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  -webkit-box-pack: center !important;
          justify-content: center !important;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  -webkit-box-pack: justify !important;
          justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.align-items-start {
  -ms-flex-align: start !important;
  -webkit-box-align: start !important;
          align-items: flex-start !important;
}
.align-items-end {
  -ms-flex-align: end !important;
  -webkit-box-align: end !important;
          align-items: flex-end !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
          align-items: center !important;
}
.align-items-baseline {
  -ms-flex-align: baseline !important;
  -webkit-box-align: baseline !important;
          align-items: baseline !important;
}
.align-items-stretch {
  -ms-flex-align: stretch !important;
  -webkit-box-align: stretch !important;
          align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #007bff !important;
}
a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.css.map *//* workaround for https://github.com/bootstrap-vue/bootstrap-vue/issues/1560 */
/* source: _input-group.scss */
.input-group > .input-group-prepend > .b-dropdown > .btn,
.input-group > .input-group-append:not(:last-child) > .b-dropdown > .btn,
.input-group > .input-group-append:last-child > .b-dropdown:not(:last-child):not(.dropdown-toggle) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .b-dropdown > .btn,
.input-group > .input-group-prepend:not(:first-child) > .b-dropdown > .btn,
.input-group > .input-group-prepend:first-child > .b-dropdown:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
/* Special styling for type=range and type=color input */
input.form-control[type="range"],
input.form-control[type="color"] {
    height: 2.25rem;
}
input.form-control.form-control-sm[type="range"],
input.form-control.form-control-sm[type="color"] {
    height: 1.9375rem;
}
input.form-control.form-control-lg[type="range"],
input.form-control.form-control-lg[type="color"] {
    height: 3rem;
}
/* Less padding on type=color */
input.form-control[type="color"] {
    padding: 0.25rem 0.25rem;
}
input.form-control.form-control-sm[type="color"] {
    padding: 0.125rem 0.125rem;
}
/* Add support for fixed layout table */
table.b-table.b-table-fixed {
    table-layout: fixed;
}
/* Busy table styling */
table.b-table[aria-busy='false'] {
    opacity: 1;
}
table.b-table[aria-busy='true'] {
    opacity: 0.6;
}
/* Sort styling */
table.b-table > thead > tr > th,
table.b-table > tfoot > tr > th {
    position: relative;
}
table.b-table > thead > tr > th.sorting,
table.b-table > tfoot > tr > th.sorting {
    padding-right: 1.5em;
    cursor: pointer;
}
table.b-table > thead > tr > th.sorting::before,
table.b-table > thead > tr > th.sorting::after,
table.b-table > tfoot > tr > th.sorting::before,
table.b-table > tfoot > tr > th.sorting::after {
    position: absolute;
    bottom: 0;
    display: block;
    opacity: 0.4;
    padding-bottom: inherit;
    font-size: inherit;
    line-height: 180%;
}
table.b-table > thead > tr > th.sorting::before,
table.b-table > tfoot > tr > th.sorting::before {
    right: 0.75em;
    content: '\2191';
}
table.b-table > thead > tr > th.sorting::after,
table.b-table > tfoot > tr > th.sorting::after {
    right: 0.25em;
    content: '\2193';
}
table.b-table > thead > tr > th.sorting_asc::after,
table.b-table > thead > tr > th.sorting_desc::before,
table.b-table > tfoot > tr > th.sorting_asc::after,
table.b-table > tfoot > tr > th.sorting_desc::before {
    opacity: 1;
}
/* Stacked table layout */
/* Derived from http://blog.adrianroselli.com/2017/11/a-responsive-accessible-table.html */
/* Always stacked */
table.b-table.b-table-stacked {
    width: 100%;
}
table.b-table.b-table-stacked,
table.b-table.b-table-stacked > tbody,
table.b-table.b-table-stacked > tbody > tr,
table.b-table.b-table-stacked > tbody > tr > td,
table.b-table.b-table-stacked > tbody > tr > th,
table.b-table.b-table-stacked > caption {
    display: block;
}
/* Hide stuff we can't deal with, or shouldn't show */
table.b-table.b-table-stacked > thead,
table.b-table.b-table-stacked > tfoot,
table.b-table.b-table-stacked > tbody > tr.b-table-top-row,
table.b-table.b-table-stacked > tbody > tr.b-table-bottom-row {
    display: none;
}
/* inter-row top border */
table.b-table.b-table-stacked > tbody > tr > :first-child {
    border-top-width: 0.4rem;
}
/* convert TD/TH contents to "cells". Caveat: child elements become cells! */
table.b-table.b-table-stacked > tbody > tr > [data-label] {
    display: grid;
    grid-template-columns: 40% auto;
    grid-gap: 0.25rem 1rem;
}
/* generate row cell "heading" */
table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
    content: attr(data-label);
    display: inline;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
}
@media all and (max-width: 575.99px) {
    /* Under SM */
    table.b-table.b-table-stacked-sm {
        width: 100%;
    }
    table.b-table.b-table-stacked-sm,
    table.b-table.b-table-stacked-sm > tbody,
    table.b-table.b-table-stacked-sm > tbody > tr,
    table.b-table.b-table-stacked-sm > tbody > tr > td,
    table.b-table.b-table-stacked-sm > tbody > tr > th,
    table.b-table.b-table-stacked-sm > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-sm > thead,
    table.b-table.b-table-stacked-sm > tfoot,
    table.b-table.b-table-stacked-sm > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-sm > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-sm > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-sm > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
@media all and (max-width: 767.99px) {
    /* under MD  */
    table.b-table.b-table-stacked-md {
        width: 100%;
    }
    table.b-table.b-table-stacked-md,
    table.b-table.b-table-stacked-md > tbody,
    table.b-table.b-table-stacked-md > tbody > tr,
    table.b-table.b-table-stacked-md > tbody > tr > td,
    table.b-table.b-table-stacked-md > tbody > tr > th,
    table.b-table.b-table-stacked-md > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-md > thead,
    table.b-table.b-table-stacked-md > tfoot,
    table.b-table.b-table-stacked-md > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-md > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-md > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-md > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
@media all and (max-width: 991.99px) {
    /* under LG  */
    table.b-table.b-table-stacked-lg {
        width: 100%;
    }
    table.b-table.b-table-stacked-lg,
    table.b-table.b-table-stacked-lg > tbody,
    table.b-table.b-table-stacked-lg > tbody > tr,
    table.b-table.b-table-stacked-lg > tbody > tr > td,
    table.b-table.b-table-stacked-lg > tbody > tr > th,
    table.b-table.b-table-stacked-lg > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-lg > thead,
    table.b-table.b-table-stacked-lg > tfoot,
    table.b-table.b-table-stacked-lg > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-lg > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-lg > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-lg > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
@media all and (max-width: 1199.99px) {
    /* under XL  */
    table.b-table.b-table-stacked-xl {
        width: 100%;
    }
    table.b-table.b-table-stacked-xl,
    table.b-table.b-table-stacked-xl > tbody,
    table.b-table.b-table-stacked-xl > tbody > tr,
    table.b-table.b-table-stacked-xl > tbody > tr > td,
    table.b-table.b-table-stacked-xl > tbody > tr > th,
    table.b-table.b-table-stacked-xl > caption {
        display: block;
    }
    /* hide stuff we can't deal with, or shouldn't show */
    table.b-table.b-table-stacked-xl > thead,
    table.b-table.b-table-stacked-xl > tfoot,
    table.b-table.b-table-stacked-xl > tbody > tr.b-table-top-row,
    table.b-table.b-table-stacked-xl > tbody > tr.b-table-bottom-row {
        display: none;
    }
    /* inter-row top border */
    table.b-table.b-table-stacked-xl > tbody > tr > :first-child {
        border-top-width: 0.4rem;
    }
    /* convert TD/TH contents to "cells". Caveat: child elements become cells! */
    table.b-table.b-table-stacked-xl > tbody > tr > [data-label] {
        display: grid;
        grid-template-columns: 40% auto;
        grid-gap: 0.25rem 1rem;
    }
    /* generate row cell "heading" */
    table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::before {
        content: attr(data-label);
        display: inline;
        text-align: right;
        overflow-wrap: break-word;
        font-weight: bold;
        font-style: normal;
    }
}
/* Details row styling */
table.b-table > tbody > tr.b-table-details > td {
    border-top: none;
}

:root {
  --fullGrid: 1 / 1 / -1 / -1;
  height: 100%;
}
header {
  /* Create grid spanning viewport width & height */
  display: grid;
  overflow: hidden;
  height: 100%;
}
.video-bg {
  /* Span the full grid */
  grid-area: var(--fullGrid);
  /* Re-size video to cover full screen while maintaining aspect ratio */
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  /* Display video below overlay */
  z-index: -1;
  height: 100%;
}
.video-bg::-webkit-media-controls {
  display: none !important;
  height: 100%;
}
.video-overlay {
  /* Span the full grid */
  grid-area: var(--fullGrid);
  /* Center Content */
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  /* Semi-transparent background */
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  padding: 61px 0;
}
.title-revenda {
  background-color: #fa8443;
  width: 100%;
  height: auto;
  padding: 10px;
  text-align: center;
  margin: 0 auto;
}
.title-revenda H2 {
    color: #fff !important;
    margin: 0 !important;
}
.title-revenda h3 {
    color: #fff !important;
    margin: 0;
    font-weight: 300 !important;
}
.content-revenda {
  background-color: #1b1a1a;
  padding: 30px;
}
.revenda-typed {
  margin: 30px 0 60px 0;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
}
.revenda-typed h2 {
    color: #fff !important;
}
.revenda-typed .vue-typer .custom.char.typed {
    color: #FFF !important;
}
.revenda-typed .vue-typer {
    font-weight: 300;
}
.revenda-1 h2 {
  margin-bottom: 10px !important;
}
.revenda-2 {
  padding: 10px 10px;
  border: solid 1px #ccc;
  text-align: center;
}
.revenda-2 i {
    color: #fa8443;
    font-size: 50px;
}

:root {
  --fullGrid: 1 / 1 / -1 / -1;
  height: 100%;
}
header {
  /* Create grid spanning viewport width & height */
  display: grid;
  overflow: hidden;
  height: 100%;
}
.video-bg {
  /* Span the full grid */
  grid-area: var(--fullGrid);
  /* Re-size video to cover full screen while maintaining aspect ratio */
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  /* Display video below overlay */
  z-index: -1;
  height: 100%;
}
.video-bg::-webkit-media-controls {
  display: none !important;
  height: 100%;
}
.video-overlay1 {
  /* Span the full grid */
  grid-area: var(--fullGrid);
  /* Center Content */
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, rgba(30, 87, 153, 0)), color-stop(18%, rgba(25, 72, 127, 0)), to(black));
  background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 1%, rgba(25, 72, 127, 0) 18%, black 100%);
  padding: 150px 0;
}
.content14 h2 {
  text-transform: uppercase;
  color: #fff !important;
  margin-bottom: 5px !important;
}
.content14 h3 {
  font-weight: 300 !important;
  color: #fff !important;
}
.content14 img {
  width: 350px;
  margin-bottom: 20px;
}
.content14 span {
  font-weight: 200 !important;
}
.content14 .btn {
  background-color: rgba(27, 26, 26, 0.7);
  color: #ccc !important;
}
.content14 .btn:hover {
  background-color: #fff;
  color: #1b1a1a !important;
}
.content14 p {
  margin: 30px 0 !important;
}
